export class Utils {

  public static get(array: any[], id: number) : any {
    for (let item of array) {
      if (item.id == id) {
        return item;
      }
    }

    return null;
  }

  public static indexOf(array: any[], id: number) : number {
    for (let i in array) {
      if (array[i].id == id) {
        return +i;
      }
    }

    return -1;
  }

  public static getNomesMeses() : string[] {
    return ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  }

  public static getFormatoCampoDate(data: Date) : string {
    return data.getFullYear() + '-' + (data.getMonth()+1 < 10 ? '0'+(data.getMonth()+1) : data.getMonth()+1) + '-' + (data.getDate() < 10 ? '0'+data.getDate() : data.getDate());
  }

  public static getFormatoCampoTime(data: Date) : string {
    return this.formatarDoisDigitos(data.getHours()) + ':' + this.formatarDoisDigitos(data.getMinutes());
  }

  public static getFormatoDateServidor(data: Date) : string {
    return data.getFullYear() + '-' + Utils.formatarDoisDigitos(data.getMonth()+1) + '-' + Utils.formatarDoisDigitos(data.getDate()) + 'T' +
      Utils.formatarDoisDigitos(data.getHours()) + ':' + Utils.formatarDoisDigitos(data.getMinutes()) + ':' + Utils.formatarDoisDigitos(data.getSeconds());
  }

  private static formatarDoisDigitos(valor: number) : string {
    return String(valor < 10 ? '0' + valor : valor);
  }

  public static compararDataComDiaAtual(data: string) : number {
    let anoAtual = new Date().getFullYear();
    let mesAtual = new Date().getMonth() + 1;
    let diaAtual = new Date().getDate();

    let tmp = data.split('T')[0];
    let dia = +tmp.split("-")[2];
    let mes = +tmp.split("-")[1];
    let ano = +tmp.split("-")[0];

    if (dia == diaAtual && mes == mesAtual && ano == anoAtual) {
      return 0;
    }

    if (ano < anoAtual || mes < mesAtual || dia < diaAtual) {
      return -1;
    }

    return 1;
  }

  public static getNumDiasTranscorridos(dataInicial: Date, dataFinal: Date) : number {
    dataInicial.setHours(0);
    dataInicial.setMinutes(0);
    dataInicial.setSeconds(0);
    dataInicial.setMilliseconds(0);

    dataFinal.setHours(0);
    dataFinal.setMinutes(0);
    dataFinal.setSeconds(0);
    dataFinal.setMilliseconds(0);

    return (dataFinal.getTime() - dataInicial.getTime()) / (1000*3600*24);
  }

  public static isCPFValido(cpf: string) : boolean {
    cpf = cpf.replace(/[^\d]+/g,'');
    if(cpf == '') return false;
    // Elimina CPFs invalidos conhecidos
    if (cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999")
      return false;

    // Valida 1o digito
    let add = 0;
    for (let i=0; i < 9; i ++)
      add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
      return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i ++)
      add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
      rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
      return false;

    return true;
  }
}
