import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbPtDateParserFormatter {

  parse(value: string) : NgbDateStruct {
    console.log('valor: '+value);

    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }

    return (date.day > 10 ? date.day : '0' + date.day) + '/' + (date.month > 10 ? date.month : '0' + date.month) + '/' + date.year;
  }
}
