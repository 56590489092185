import { Pipe, PipeTransform } from '@angular/core';
import {Turno} from "../model/turno";

@Pipe({
  name: 'formatadorTurno'
})
export class FormatadorTurnoPipe implements PipeTransform {

  transform(value: any): any {
    if (!value) return '';

    return Turno[value];
  }

}
