import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private static urlRaiz: string = environment.urlRaiz;
  private static caminhoLogo: string = environment.caminhoLogo;
  private static caminhoLogoPainel: string = environment.caminhoLogoPainel;
  private static idUsuarioSuporte: number = 555;
  private static tituloPadraoEventoAgenda = environment.tituloPadraoEventoAgenda;
  private static urlPlataformaAulas: string = environment.urlPlataformaAulas;
  private static padraoNotificarDocumentos: boolean = environment.padraoNotificarDocumentos ? environment.padraoNotificarDocumentos : false;
  private static textoMensalidade: string = environment.textoMensalidade;
  private static exibirNivelEducacaoNoHistorico: boolean = environment.exibirNivelEducacaoNoHistorico;

  constructor() { }

  public static getUrlRaiz() {
    return this.urlRaiz;
  }

  public static getCaminhoLogo() {
    return this.caminhoLogo;
  }

  public static getCaminhoLogoPainel() {
    return this.caminhoLogoPainel;
  }

  public static getIDUsuarioSuporte() {
    return this.idUsuarioSuporte;
  }

  public static getTituloPadraoEventoAgenda() {
    return this.tituloPadraoEventoAgenda;
  }

  public static getUrlPlataformaAulas() {
    return this.urlPlataformaAulas;
  }

  public static getPadraoNotificarDocumentos() {
    return this.padraoNotificarDocumentos;
  }

  public static getTextoMensalidade() {
    return this.textoMensalidade;
  }

  public static getExibirNivelEducacaoNoHistorico() {
    return this.exibirNivelEducacaoNoHistorico;
  }
}
