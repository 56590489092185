import { Injectable, Injector } from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable({
	providedIn: 'root'
})
export class AvaliacaoService extends BaseService {

	constructor(private injector: Injector) {
		super(injector);

		this.setURL('avaliacoes');
	}

  public obterFichasTurma(idTurma, idPeriodo) : Observable<any> {
    return this.http.get(this.getURL() + '/obter_fichas_turma/'+idTurma+'/'+idPeriodo, {responseType: 'blob'}).map(response => response as any);
  }
}
