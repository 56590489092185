import { Input, Directive, TemplateRef, ViewContainerRef } from '@angular/core';

import {AppService} from "../app.service";

@Directive({
  selector: '[temPermissao]'
})
export class TemPermissaoDirective {

  constructor(private appService: AppService,
              private templateRef: TemplateRef<any>,
              private viewContainerRef: ViewContainerRef) { }

  @Input() set temPermissao(funcionalidade) {
    let usuarioLogado = this.appService.getUsuarioLogado();

    if (!usuarioLogado)
      return;

    //verifica se o usuario logado possui a funcionalidade informada
    if (usuarioLogado.funcionalidades.indexOf(funcionalidade) > -1) {

      //exibe a tag que usa a diretiva
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

}
