import { Injectable, Injector } from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from 'rxjs/Observable';

@Injectable({
	providedIn: 'root'
})
export class GradeCurricularService extends BaseService {

	constructor(private injector: Injector) {
		super(injector);

		this.setURL('grades_curriculares');
	}

  public obterDadosGerais(id: number) : Observable<any> {
    return this.http.get(this.getURL() + '/obter_dados_gerais/' + id).map(response => response as any);
  }

}
