import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {Aluno} from "../model/aluno";

@Injectable()
export class AlunoSelecionadoService {

  alunoSelecionado:Observable<any>;

  private observerAlunoSelecionado:Observer<Aluno>;


  constructor() {
    this.alunoSelecionado = new Observable(observer => {
      this.observerAlunoSelecionado = observer;
    }).share();
  }

  public notificarAlunoSelecionado(aluno:Aluno) {
    let timer = setInterval(() => {
      if (this.observerAlunoSelecionado) {
        this.observerAlunoSelecionado.next(aluno);
        clearInterval(timer);
      }
    }, 100);
  }
}
