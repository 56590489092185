import {Serie} from "./serie";
import {Turno} from "./turno";

export class GradeCurricular {
	id: number;
	serie: Serie;
	turno: Turno;
	ano: number;
  utilizarEntrevista: boolean;

  listaDiasLetivos: number[];
  numMatriculasAguardandoConfirmacao: number=0;

  constructor() {
    this.serie = new Serie();
  }
}
