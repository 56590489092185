import {Injector, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {Usuario} from "../model/usuario";

@Injectable({
  providedIn: 'root'
})
export class SistemaService {

  constructor(private http: HttpClient) { }

  public getUsuarioLogado() : Observable<Usuario> {
    return this.http.get('usuario_logado').map(response => response as Usuario);
  }

  public podeGerarContrato() : Observable<boolean> {
    return this.http.get('pode_gerar_contrato').map(response => response as boolean);
  }

  public podeGerarRequerimentoMatricula() : Observable<boolean> {
    return this.http.get('pode_gerar_requerimento_matricula').map(response => response as boolean);
  }

  public podeGerarArqivoCCBEU() : Observable<boolean> {
    return this.http.get('pode_gerar_arquivo_ccbeu').map(response => response as boolean);
  }

  public getValorMulta() : Observable<number> {
    return this.http.get('valor_multa').map(response => response as number);
  }

  public getValorJuros() : Observable<number> {
    return this.http.get('valor_juros').map(response => response as number);
  }

  public podeExibirOpcaoAdicionarObservacaoFichaMatricula() : Observable<boolean> {
    return this.http.get('pode_exibir_opcao_adicionar_observacao_ficha_matricula').map(response => response as boolean);
  }

  public podeGerarDeclaracaoFrequencia() : Observable<boolean> {
    return this.http.get('pode_gerar_declaracao_frequencia').map(response => response as boolean);
  }

  public podeGerarFichaMatriculaParaPreencher() : Observable<boolean> {
    return this.http.get('pode_gerar_ficha_matricula_para_preencher').map(response => response as boolean);
  }

  public podeGerarAtas() : Observable<boolean> {
    return this.http.get('pode_gerar_atas').map(response => response as boolean);
  }

  public podeGerarDeclaracaoImpostoRenda() : Observable<boolean> {
    return this.http.get('pode_gerar_declaracao_imposto_renda').map(response => response as boolean);
  }

  public podeGerarTermoAutorizacaoRetornoAulasPresenciais() : Observable<boolean> {
    return this.http.get('pode_gerar_termo_autorizacao_retorno_aulas_presenciais').map(response => response as boolean);
  }

  public salvarTokenPainel(token: string) : Observable<any> {
    return this.http.put('salvar_token_painel', {token: token}).map(response => response as any);
  }
}
