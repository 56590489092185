import { Pipe, PipeTransform } from '@angular/core';
import {NivelEducacao} from "../model/nivel-educacao";

@Pipe({
  name: 'formatadorNivelEducacao'
})
export class FormatadorNivelEducacaoPipe implements PipeTransform {

  transform(value: any): string {
    return NivelEducacao[value];
  }

}
