import {Injector, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {saveAs} from "file-saver";
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigService} from "./config.service";

@Injectable()
export class ArquivoService {

  constructor(private http: HttpClient) {
  }

  public download(arquivo) : void {
    this.http.get(ConfigService.getUrlRaiz() + 'arquivos/download/'+arquivo.id, {responseType: 'blob'}).subscribe(file => {
      saveAs(file, arquivo.nome);
    });
  }

  public downloadAsBlob(arquivo) : Observable<any> {
    return this.http.get(ConfigService.getUrlRaiz() + 'arquivos/download/'+arquivo.id, {responseType: 'blob'}).pipe(map(file => file));
  }
}
