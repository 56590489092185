import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatadorTipoDivulgacao'
})
export class FormatadorTipoDivulgacaoPipe implements PipeTransform {

  transform(value: any): string {
    if (value == 'MURAL') return 'Mural';
    else if (value == 'NOTIFICACAO') return 'Notificação';

    return '';
  }

}
