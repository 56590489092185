import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataSemHora'
})
export class DataSemHoraPipe implements PipeTransform {

  transform(strData: string): any {
    if (strData && strData.split('-').length > 1) {
      return strData.split('-')[0].trim();
    }

    return strData;
  }

}
