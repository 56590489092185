import { Pipe, PipeTransform } from '@angular/core';
import {StatusMatricula} from "../model/statusmatricula";

@Pipe({
  name: 'formatadorStatusMatricula'
})
export class FormatadorStatusMatriculaPipe implements PipeTransform {

  transform(value: any): any {
    return StatusMatricula[value];
  }

}
