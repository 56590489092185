import { Component, OnInit, Input } from '@angular/core';
import { IOption } from 'ng-select';

import {EstadoService} from "../../service/estado.service";
import {Estado} from "../../model/estado";

@Component({
  selector: 'select-estado',
  templateUrl: './select-estado.component.html',
  styleUrls: ['./select-estado.component.scss',
    '../../../vendor/libs/ng-select/ng-select.scss']
})
export class SelectEstadoComponent implements OnInit {

  @Input() estado: Estado;
  public estados: IOption[]=[];

  public tempEstados: Estado[];


  constructor(private estadoService: EstadoService) { }

  ngOnInit() {
    if (!this.estado) {
      this.estado = new Estado();
    }

    this.estadoService.listar().subscribe(lista => {
      this.tempEstados = lista;

      for (let estado of lista) {
        this.estados.push(({label: estado.sigla, value: estado.sigla}));
      }
    });
  }

  public onEstadoSelecionado() {
    for (let estado of this.tempEstados) {
      if (estado.sigla == this.estado.sigla) {
        this.estado.id = estado.id;
        break;
      }
    }
  }
}
