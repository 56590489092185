import { Component, Input, HostBinding, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';
import { LayoutService } from '../../layout/layout.service';
import {Unidade} from "../../model/unidade";
import {FormUnidadeComponent} from "../../form-unidade/form-unidade.component";
import {UnidadeService} from "../../service/unidade.service";
import {DadosColaboradorLogadoComponent} from "../../dados-colaborador-logado/dados-colaborador-logado.component";
import {Colaborador} from "../../model/colaborador";
import {SuporteService} from "../../shared/suporte.service";

@Component({
  selector: 'app-layout-navbar',
  templateUrl: './layout-navbar.component.html',
  styles: [
    ':host { display: block; }' +
    '.no-border { border-width: 0px; }' +
    '.unidade-selecionada {font-size: 1rem; padding-left: 0px; } '
  ]
})
export class LayoutNavbarComponent implements OnInit {
  public unidadeSel: Unidade;
  public unidades: Unidade[]=[];

  public isSuporteOnline: boolean = false;
  public exibirNotificacaoMsgSuporte: boolean = false;

  isExpanded = false;

  @Input() sidenavToggle = true;

  @HostBinding('class.layout-navbar') private hostClassMain = true;

  public usuarioLogado: Colaborador;

  constructor(private appService: AppService,
              private layoutService: LayoutService,
              private modalService: NgbModal,
              private router: Router,
              private unidadeService: UnidadeService) {
    this.unidadeSel = new Unidade();
    this.usuarioLogado = new Colaborador();
  }

  ngOnInit() {
    this.usuarioLogado = this.appService.getUsuarioLogado();

    this.appService.getUnidadeCorrente().then(unidade => {
      this.selecionarUnidade(unidade);
    });

    this.unidadeService.buscar({atributosBuscados: ['nome'], campoOrdenado: 'nome'}).subscribe(lista => {
      this.unidades = lista;

      if (this.unidadeSel && this.unidadeSel.id) {
        //verifica se a unidade selecionada é alguma das obtidas na lista
        let flag = false;
        for (let unid of lista) {
          if (unid.nome == this.unidadeSel.nome) {
            flag = true;
          }
        }

        //se a unidade não estiver na lista então usa a primeira da lista como selecionada
        if (!flag) {
          this.router.navigate(['/login']);

          this.appService.logout();
        }
      }
    });


    this.isSuporteOnline = SuporteService.getIsSuporteOnline();

    //monitora o status do suporte
    SuporteService.suporteOnline.subscribe((isOnline: boolean) => {
      this.isSuporteOnline = isOnline;
    });

    //monitora a chegada de novas mensagens
    SuporteService.novaMensagem.subscribe((temNova: boolean) => {
      //verifica se tem nova mensagem mas a tela do suporte já está aberta. Neste caso não precisa exibir o indicador
      if (temNova && this.appService.getURLCorrente().indexOf('suporte') > -1)
        temNova = false;

      this.exibirNotificacaoMsgSuporte = temNova;
    });
  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  public selecionarUnidade(unidade) {
    this.unidadeSel = unidade;

    //atualiza a unidade corrente
    this.appService.setUnidadeCorrente(unidade);
  }

  public carregarUnidade() {
    //abre a unidade selecionada
    this.router.navigateByUrl('redirecionador');
  }

  public cadastrarUnidade() {
    this.modalService.open(FormUnidadeComponent, { windowClass: 'modal-lg animate' }).result.then((result) => {
      this.unidades.push(result);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

  public editarUnidade() {
    let modal = this.modalService.open(FormUnidadeComponent, { windowClass: 'modal-lg animate' });
    modal.result.then((result) => {
      this.unidadeSel = result;

    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
    modal.componentInstance.id = this.unidadeSel.id;
  }

  public abrirDadosColaboradorLogado() {
    this.modalService.open(DadosColaboradorLogadoComponent, { windowClass: 'modal-md animate' }).result.then((colab) => {
      this.usuarioLogado = colab;
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }
}
