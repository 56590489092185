import {DadosBancarios} from "./dados-bancarios";
import {Posicao} from "./posicao";
import {EmailAdicional} from "./email-adicional";

export class Unidade {
	id: number;
	nome: string;
	matriz: boolean;
	endereco: string;
	cnpj: string;
	dadosBancarios: DadosBancarios;
	posicao: Posicao;
  emailsAdicionais: EmailAdicional[]=[];

	constructor() {
	  this.posicao = new Posicao();
  }
}
