import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {Professor} from "../model/professor";

@Injectable()
export class ProfessorGerenciadoService {

  professorAlocado: Observable<any>;
  professorDesalocado: Observable<any>;

  private observerProfessorAlocado:Observer<Professor>;
  private observerProfessorDesalocado:Observer<Professor>;


  constructor() {
    this.professorAlocado = new Observable(observer => {
      this.observerProfessorAlocado = observer;
    }).share();

    this.professorDesalocado = new Observable(observer => {
      this.observerProfessorDesalocado = observer;
    }).share();
  }

  public notificarProfessorAlocado(professor:Professor) {
    let timer = setInterval(() => {
      if (this.observerProfessorAlocado) {
        this.observerProfessorAlocado.next(professor);
        clearInterval(timer);
      }
    }, 100);

  }

  public notificarProfessorDesalocado(professor:Professor) {
    let timer = setInterval(() => {
      if (this.observerProfessorDesalocado) {
        this.observerProfessorDesalocado.next(professor);
        clearInterval(timer);
      }
    }, 100);

  }
}
