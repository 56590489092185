import {Estado} from "./estado";

export class Endereco {
	id: number;
	logradouro: boolean;
	numero: string;
	complemento: string;
	bairro: string;
	cidade: string;
  estado: Estado = new Estado();
	cep: string;
}
