import {Cargo} from "./cargo";
import {Usuario} from "./usuario";

export class Colaborador extends Usuario {
	id: number;
	senha: string;
	cargo: Cargo;
	funcionalidades: string[];
  acessoLiberadoSistema: boolean = false;

  senhaAtual: string;
  novaSenha: string;
  confirmacaoNovaSenha: string;
}
