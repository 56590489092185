import {Injector, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {ConfigService} from "./config.service";

@Injectable()
export class BaseService {

  private url;
  http: HttpClient;

  constructor(injector: Injector) {
    this.http = injector.get(HttpClient);
  }

  setURL(url) {
    this.url = url;
  }

  getURL() {
    return this.url;
  }

  listar(): Observable<any> {
    return this.http.post(this.url + '/search', {}).map(response => response as any);
  }

  buscar(busca, pagina?, numItens?): Observable<any> {
    return this.http.post(this.url + '/search' + (pagina != null ? '?pagina=' + pagina + '&numItens=' + numItens : ''), busca).map(response => response as any);
  }

  buscarPaginado(busca, pagina, numItens): Observable<any> {
    return this.http.post(this.url + '/buscar_paginado?pagina=' + pagina + '&numItens=' + numItens, busca).map((response:Response) => response as any);
  }

  get(id): Observable<any> {
    return this.http.get(this.url + '/' +  id).map(response => response as any);
  }

  salvar(obj): Observable<number> {
    return this.http.put(this.url, obj).map(response => response as number);
  }

  remover(obj): Observable<any> {
    return this.http.delete(this.url +'/'+obj.id).map(response => response as String);
  }

  downloadArquivo(busca: any, sufixoUrl: string): Observable<Blob> {
    return this.http.post(this.url + sufixoUrl, busca, {responseType: 'blob'}).map(response => response as any);
  }
}
