export enum StatusMatricula {
  PENDENTE = 'Pendente',
  CONFIRMADA = 'Confirmada',
  CANCELADA = 'Cancelada',
  APROVADO = 'Aprovado',
  REPROVADO = 'Reprovado',
  PRE_MATRICULA_PENDENTE = 'Pré-matrícula Pendente',
  PRE_MATRICULA_CONFIRMADA = 'Pré-matrícula Confirmada',
  DESISTENTE = 'Desistente',
  TRANSFERIDO = 'Transferido'
}
