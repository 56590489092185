import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {Turma} from "../model/turma";

@Injectable()
export class TurmaGerenciadaService {

  turmaCadastrada: Observable<any>;
  turmaRemovida: Observable<any>;

  private observerTurmaCadastrada:Observer<Turma>;
  private observerTurmaRemovida:Observer<Turma>;


  constructor() {
    this.turmaCadastrada = new Observable(observer => {
      this.observerTurmaCadastrada = observer;
    }).share();

    this.turmaRemovida = new Observable(observer => {
      this.observerTurmaRemovida = observer;
    }).share();
  }

  public notificarTurmaCadastrada(turma:Turma) {
    let timer = setInterval(() => {
      if (this.observerTurmaCadastrada) {
        this.observerTurmaCadastrada.next(turma);
        clearInterval(timer);
      }
    }, 100);

  }

  public notificarTurmaRemovida(turma:Turma) {
    let timer = setInterval(() => {
      if (this.observerTurmaRemovida) {
        this.observerTurmaRemovida.next(turma);
        clearInterval(timer);
      }
    }, 100);

  }
}
