import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatadorStatusMensalidade'
})
export class FormatadorStatusMensalidadePipe implements PipeTransform {

  transform(value: any): any {
    if (value == 'EM_ABERTO') {
      return 'Em aberto';

    } else if (value == 'PAGA') {
      return 'Paga';

    } else if (value == 'VENCIDA') {
      return 'Vencida';
    }

    return '';
  }

}
