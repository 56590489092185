import { Injectable, Injector } from '@angular/core';
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class NomeAvaliacaoService extends BaseService {

  constructor(private injector: Injector) {
    super(injector);

    this.setURL('nomes_avaliacoes');
  }
}
