import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatadorStatusBoleto'
})
export class FormatadorStatusBoletoPipe implements PipeTransform {

  transform(value: any): string {
    if (value == 'AGUARDANDO_PAGAMENTO' || value == 'REGISTRADO')
      return 'Aguardando pagamento';
    else if (value == 'LIQUIDADO')
      return 'Pago';
    else if (value == 'BAIXADO')
      return 'Baixado';
    else if (value == 'PROTESTADO')
      return 'Protestado';
    else if (value == 'CANCELADO')
      return 'Cancelado';
    else if (value == 'VENCIDO')
      return 'Vencido';

    return '';
  }

}
