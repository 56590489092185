import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {GradeCurricular} from "../model/gradecurricular";

@Injectable()
export class GradeCurricularSelecionadaService {

  gradeSelecionada: Observable<any>;

  private observerGradeSelecionada:Observer<GradeCurricular>;


  constructor() {
    this.gradeSelecionada = new Observable(observer => {
      this.observerGradeSelecionada = observer;
    }).share();
  }

  public notificarGradeSelecionada(grade:GradeCurricular) {
    let timer = setInterval(() => {
      if (this.observerGradeSelecionada) {
        this.observerGradeSelecionada.next(grade);
        clearInterval(timer);
      }
    }, 100);

  }

}
