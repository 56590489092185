import { Directive, ElementRef, Input } from '@angular/core';
import {FormatoMoedaService} from "./formato-moeda.service";


@Directive({
  selector: '[formatoMoeda]',
  host: {
    '(ngModelChange)': 'onInputChange($event)'
  }
})
export class FormatoMoedaDirective {

  constructor(private el: ElementRef,
              private formatoMoedaService: FormatoMoedaService) {

    //aguarda 100 milissegundos para aguardar o campo ser carregado com o valor do model
    setTimeout(() => {
      //caso não existe valor inserido no input insere '0' por padrão
      if (!this.el.nativeElement.value)
        this.el.nativeElement.value = 'R$ 0,00';
    }, 100);

  }

  onInputChange(event) {
    this.el.nativeElement.value = this.formatoMoedaService.formatar(event)
  }
}
