import { NgModule } from '@angular/core';
import {SharedModule} from "../shared/shared.module";

import {DadosColaboradorLogadoComponent} from "./dados-colaborador-logado.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [DadosColaboradorLogadoComponent],
  entryComponents: [DadosColaboradorLogadoComponent]
})
export class DadosColaboradorLogadoModule { }
