import { Injectable, Injector } from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable({
	providedIn: 'root'
})
export class ColaboradorService extends BaseService {

	constructor(private injector: Injector) {
		super(injector);

		this.setURL('colaboradores');
	}

  public liberarAcesso(id, email) : Observable<any> {
    return this.http.get(this.getURL() + '/liberar_acesso/'+id+'?email='+email).map(response => response as any);
  }

  public bloquearAcesso(id) : Observable<any> {
    return this.http.get(this.getURL() + '/bloquear_acesso/'+id).map(response => response as any);
  }

  public getFuncionalidades(modulos: string[]) : Observable<any> {
    return this.http.post(this.getURL() + '/get_mapa_funcionalidades', modulos).map(response => response as any);
  }

  public salvarDadosAcessoSistema(colaborador) : Observable<any> {
    return this.http.post(this.getURL() + '/salvar_dados_acesso_sistema', colaborador).map(response => response as any);
  }
}
