import { Directive, ElementRef, Input, Renderer, OnInit } from '@angular/core';

@Directive({
  selector: '[mascara]',
  host: {
    '(ngModelChange)': 'onInputChange($event)'
  }
})
export class MascaraDirective implements OnInit {

  @Input('mascara') valorMascara: string;

  constructor(private el: ElementRef, private renderer: Renderer) {
  }

  ngOnInit() {
    //Limita a quantidade de caracteres digitados com base no tamanho da mascara
    this.renderer.setElementAttribute(this.el.nativeElement, 'maxlength', String(this.valorMascara.length));
  }

  onInputChange(event) {
    //extrai todos os caracteres não numéricos
    var valor = new String(event.replace(/\D/g, ''));

    var resultado = '';

    //indice para os caracteres da mascara
    var maskIndex = 0;

    //percorre todos os caracteres do valor informado
    for (var i = 0; i < valor.length; i++, maskIndex++) {
      //se a posição corrente da mascara não for um número, então insere o caracter e incrementa no indice da mascara
      while (this.valorMascara.charAt(maskIndex) != '0') {
        resultado += this.valorMascara.charAt(maskIndex);
        maskIndex++;
      }

      //insere o numero da posição indicada
      resultado += valor.charAt(i);

      //se o resultando alcançou o tamanho da máscara então termina a iteração
      if (resultado.length == this.valorMascara.length) {
        break;
      }
    }

    this.el.nativeElement.value = resultado;
  }
}
