import { Component, Input, ChangeDetectionStrategy, AfterViewInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';
import { LayoutService } from '../layout.service';
import {MenuService} from "../../shared/menu.service";
import {TipoMenu} from "../../shared/tipo-menu";
import {TurmaService} from "../../service/turma.service";
import {Turma} from "../../model/turma";
import {GradeCurricular} from "../../model/gradecurricular";
import {GradeCurricularService} from "../../service/grade-curricular.service";
import {TipoUsuario} from "../../model/tipo-usuario";
import {ProfessorService} from "../../service/professor.service";
import {Utils} from "../../shared/utils.service";
import {NivelEducacao} from "../../model/nivel-educacao";
import {PushNotificationService} from "../../shared/push-notification.service";
import {DivulgacaoService} from "../../comunicacao/service/divulgacao.service";

@Component({
  selector: 'app-layout-sidenav',
  templateUrl: './layout-sidenav.component.html',
  styles: [
    ':host { display: block; }' +
    '.botao-desabilitado > a.sidenav-link {color:black}'
  ]
})
export class LayoutSidenavComponent implements AfterViewInit {
  @Input() orientation = 'vertical';

  @HostBinding('class.layout-sidenav') private hostClassVertical = false;
  @HostBinding('class.layout-sidenav-horizontal') private hostClassHorizontal = false;
  @HostBinding('class.flex-grow-0') private hostClassFlex = false;

  public tipoMenu: TipoMenu = TipoMenu.ESCOLA;

  public grades: GradeCurricular[]=[];
  public turmas: Turma[]=[];
  public turmasEscondidas: Turma[]=[];

  public numDivulgacoesAguardandoAprovacao: number=0;

  private promise: any;

  private mapaMenu: string[] = [];

  constructor(private router: Router, private appService: AppService, private layoutService: LayoutService,
              private gradeService: GradeCurricularService,
              private turmaService: TurmaService,
              private professorService: ProfessorService,
              private menuService: MenuService,
              private divulgacaoService: DivulgacaoService,
              private pushNotificationService: PushNotificationService) {
    // Set host classes
    this.hostClassVertical = this.orientation !== 'horizontal';
    this.hostClassHorizontal = !this.hostClassVertical;
    this.hostClassFlex = this.hostClassHorizontal;


    if (localStorage.getItem('tipoMenu')) {
      let tipoMenu = +localStorage.getItem('tipoMenu');

      if (tipoMenu == 0) {
        this.tipoMenu = TipoMenu.ESCOLA;

      } else if (tipoMenu == 1) {
        this.tipoMenu = TipoMenu.GRADE_CURRICULAR;

      } else if (tipoMenu == 2) {
        this.tipoMenu = TipoMenu.TURMA;
      }

      this.carregarMenu();
    }

    this.menuService.trocarMenu.subscribe((tipo : TipoMenu) => {
      localStorage.setItem('tipoMenu', String(tipo).valueOf());

      this.tipoMenu = tipo;

      this.carregarMenu();
    });


    //obtem a quantidade de divulgacoes aguardando aprovacao
    this.divulgacaoService.buscar({aguardandoAprovacao: true, atributosBuscados: ['id']}).subscribe(lista => {
      this.numDivulgacoesAguardandoAprovacao = lista.length;
    });

    //aguarda as notificacoes que informam que existem novas divulgações para aprovar
    PushNotificationService.currentMessage.subscribe(payload => {

      if (payload && payload['data'] && payload['data']['numDivulgacoesAguardandoAprovacao']) {
        this.numDivulgacoesAguardandoAprovacao = +payload['data']['numDivulgacoesAguardandoAprovacao'];
      }

    });


    //configura as urls de cada menu
    this.mapaMenu['comunicacao'] = ['/comunicacao/agenda', '/comunicacao/atendimento', '/comunicacao/divulgacoes', '/comunicacao/documentos'];

    this.mapaMenu['academico'] = ['/comunicacao/contatos', '/academico/ficha_acompanhamento', '/comunicacao/faltas_alunos', '/pesquisa/lista', '/video_aula/lista'];

    this.mapaMenu['financeiro'] = ['/financeiro/visao_geral', '/financeiro/movimentacoes', '/financeiro/boletos', '/financeiro/remessas', '/financeiro/notas_fiscais',
      '/financeiro/relatorios'];

    this.mapaMenu['controle'] = ['/comunicacao/configuracoes', '/arquivos/lista-documento', '/comunicacao/categorias_aluno',
      '/pessoas/colaboradores', '/comercial/entrevista'];

    this.mapaMenu['grade_curricular'] = ['/academico/grade_curricular/'];

    this.mapaMenu['turma'] = ['/academico/turma/'];
  }

  private carregarMenu() {
    if (this.tipoMenu == TipoMenu.GRADE_CURRICULAR) {
      this.carregarGrades();

    } else if (this.tipoMenu == TipoMenu.TURMA) {
      this.carregarTurmas();
    }
  }

  private carregarGrades() {
    this.appService.getUnidadeCorrente().then(unidade => {
      this.appService.getGradeCorrentePromise().then(grade => {

        this.gradeService.buscar({id: grade.id, atributosBuscados: ['ano']}).subscribe(lista => {
          let anoLetivo = lista[0].ano;

          //obtem apenas as grades do mesmo ano da grade selecionada
          this.gradeService.buscar({idUnidade: unidade.id, ano: anoLetivo, atributosBuscados: ['serie.nome', 'serie.nivelEducacao', 'turno'], campoOrdenado: 'serie.nome'}).subscribe(lista => {
            this.grades = lista;
          });

        });

      });
    });
  }

  private carregarTurmas() {
    //verifica se o usuário é professor, pois neste caso apenas lista as turmas associadas ao mesmo
    let usuario = this.appService.getUsuarioLogado();
    if (TipoUsuario[usuario.tipo] == TipoUsuario.PROFESSOR) {
      this.turmas = [];

      this.professorService.buscar({idUsuario: usuario.id, atributosBuscados: ['turma.id', 'turma.codigo', 'turma.gradeCurricular.serie.nome', 'turma.gradeCurricular.serie.nivelEducacao', 'turma.gradeCurricular.turno'],
        campoOrdenado: 'turma.codigo'}).subscribe(lista => {
        console.log(lista);
        for (let prof of lista) {
          let turma = prof.turma;

          //verifica se a turma já foi inserida
          if (Utils.indexOf(this.turmas, turma.id) > -1) continue;

          turma.codigo += '<br /><small>' + turma.gradeCurricular.serie.nome+' '+NivelEducacao[turma.gradeCurricular.serie.nivelEducacao]+' - ' + turma.gradeCurricular.turno + '</small>';

          this.turmas.push(turma);
        }
      });

      //obtem a lista de turmas que foram escondidas pelo professor
      this.turmasEscondidas = [];

      this.professorService.buscar({idUsuario: usuario.id, esconderTurma: true, atributosBuscados: ['turma.id', 'turma.codigo', 'turma.gradeCurricular.serie.nome',
        'turma.gradeCurricular.serie.nivelEducacao', 'turma.gradeCurricular.turno'], campoOrdenado: 'turma.codigo'}).subscribe(lista => {
        console.log(lista);
        for (let prof of lista) {
          let turma = prof.turma;

          //verifica se a turma já foi inserida
          if (Utils.indexOf(this.turmasEscondidas, turma.id) > -1) continue;

          turma.codigo += '<br /><small>' + turma.gradeCurricular.serie.nome+' '+NivelEducacao[turma.gradeCurricular.serie.nivelEducacao]+' - ' + turma.gradeCurricular.turno + '</small>';

          this.turmasEscondidas.push(turma);
        }
      });

    } else {
      //carrega todas as turmas da grade corrente
      this.appService.getGradeCorrentePromise().then(grade => {
        this.turmaService.buscar({idGradeCurricular: grade.id, atributosBuscados: ['codigo']}).subscribe(lista => {
          this.turmas = lista;
        });
      });
    }
  }

  public exibirRelacaoResponsaveisDependentes() {
    window.open('#/pessoas/relacao_responsaveis_dependentes');
  }

  public sair() {
    if (this.promise)
      clearInterval(this.promise);

    //limpa os dados e exibe a tela de login
    this.appService.logout();

    this.router.navigate(['/login']);
  }


  ngAfterViewInit() {
    // Safari bugfix
    this.layoutService._redrawLayoutSidenav();
  }

  getClasses() {
    let bg = this.appService.layoutSidenavBg;

    if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '');
    }

    return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`;
  }

  isActive(url) {
    return this.router.isActive(url, false);
  }

  isMenuActive(menu) {
    //verifica se a URL atual pertence ao menu informado
    for (let url of this.mapaMenu[menu]) {
      let res = this.isActive(url);

      if (res) {
        return true;
      }
    }

    return false;
  }

  isMenuOpen(menu) {
    return this.isMenuActive(menu);
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }
}
