import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router }   from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {ConfigService} from "./service/config.service";
import {AppService} from "./app.service";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  private promiseInativo: any;

  constructor(private router: Router,
              private appService: AppService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //verifica se a url requisitada não é as de monitoramento do suporte
    // if (!req.url.match('.*chat/suporte_online') && !req.url.match('.*chat/mensagens')) {
      //verifica se o timeout de inatividade já está ativado
      // if (this.promiseInativo)
      //   clearTimeout(this.promiseInativo);
      //
      //verifica se a flag de manter logado foi ativada
      // if (!this.appService.getManterLogado()) {
      //   //inicia o timeout de inatividade para deslogar no caso do tempo inativo ter sido atingido
      //   this.promiseInativo = setTimeout(() => {
      //     this.appService.logout();
      //
      //     this.router.navigate(['/login']);
      //   }, 10 * 60 * 1000); //inatividade por 10 minutos
      // }
    // }


    if (!req.url.startsWith('http')) {

      //verifica se existe token informado, caso contrário exibe a tela de login
      if (!localStorage.getItem('token')) {
        this.router.navigate(['/login']);
        return;
      }

      //Clona a requisicao para inserir o item no cabeçalho e a URL inserindo o dominio
      let authReq = req.clone({
        url: ConfigService.getUrlRaiz().concat(req.url),
        headers: req.headers.set('tk_auth', localStorage.getItem('token'))
      });
// console.log(authReq);
      localStorage.removeItem('sessaoExpirada');

      //Utiliza a requisicao montada ao inves da original
      return next.handle(authReq)
        .catch(error => {
          //verifica se a sessão expirou
          if (error.status == 401) {
            localStorage.removeItem('token');
            localStorage.setItem('sessaoExpirada', 'true');

            this.router.navigate(['/login']);

            return [];

          } else if (error.status == 403) {
            this.router.navigate(['/login']);

            return [];
          }

          return Observable.throw(error);
        });

    } else if (localStorage.getItem('token')) {

      let authReq = req.clone({
        url: req.url,
        headers: req.headers.set('tk_auth', localStorage.getItem('token'))
      });

      return next.handle(authReq);

    } else {
      return next.handle(req);
    }
  }
}
