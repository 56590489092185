import { Injectable, Injector } from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable({
	providedIn: 'root'
})
export class TurmaService extends BaseService {

	constructor(private injector: Injector) {
		super(injector);

		this.setURL('turmas');
	}

  public obterDadosGerais(id) : Observable<any> {
    return this.http.get(this.getURL() + '/obter_dados_gerais/'+id).map(response => response as any);
  }


  public obterDiarioTurma(idProfessor, mes) : Observable<any> {
    return this.http.get(this.getURL() + '/obter_diario/'+idProfessor+'/'+mes, {responseType: 'blob'}).map(response => response as any);
  }

  public obterPlanilhaAlunos(idTurma) {
    return this.http.get(this.getURL() + '/obter_planilha_alunos/' + idTurma, {responseType: 'blob'}).map(response => response as any);
  }

  public obterRelatorioNotasInferioresMedia(id: number, idPeriodo: number) : Observable<any> {
    return this.http.get(this.getURL() + '/obter_relatorio_notas_inferiores_media/' + id + '/' + idPeriodo, {responseType: 'blob'}).map(response => response as any);
  }

  public obterAtaMatriculas(id: number) : Observable<any> {
    return this.http.get(this.getURL() + '/obter_ata_matriculas/' + id, {responseType: 'blob'}).map(response => response as any);
  }

  public obterTurmasProfessor(idProfessor: number) : Observable<any> {
	  return this.http.get(this.getURL() + '/obter_turmas_professor/' + idProfessor).map(response => response as any);
  }
}
