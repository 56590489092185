import { Component } from '@angular/core';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AppService } from './app.service';
import { LayoutService } from './layout/layout.service';
import {MenuService} from "./shared/menu.service";
import {TipoMenu} from "./shared/tipo-menu";
import {PushNotificationService} from "./shared/push-notification.service";
import {SuporteService} from "./shared/suporte.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [':host { display: block; }']
})
export class AppComponent {

  constructor(private router: Router, private appService: AppService, private layoutService: LayoutService,
              private pushNotificationService: PushNotificationService,
              private suporteService: SuporteService,
              private menuService: MenuService) {
    // Subscribe to router events to handle page transition
    this.router.events.subscribe(this.navigationInterceptor.bind(this));

    // Disable animations and transitions in IE10 to increase performance
    if (typeof document['documentMode'] === 'number' && document['documentMode'] < 11) {
      const style = document.createElement('style');
      style.textContent = `
        * {
          -ms-animation: none !important;
          animation: none !important;
          -ms-transition: none !important;
          transition: none !important;
        }`;
      document.head.appendChild(style);
    }

    //verifica se o usuario está logado para iniciar o serviço de push notification
    if (this.appService.getUsuarioLogado()) {
      //obtem a permissao para receber notificacoes
      this.pushNotificationService.requestPermission();
      this.pushNotificationService.receiveMessage();
    }

    //inicializa o serviço do suporte
    this.suporteService.iniciar();
  }

  private navigationInterceptor(e: RouterEvent) {
    if (e instanceof NavigationStart) {
      // Set loading state
      document.body.classList.add('app-loading');

      this.trocarMenu(e.url);
    }

    if (e instanceof NavigationEnd) {
      // Scroll to top of the page
      this.appService.scrollTop(0, 0);
      this.appService.setURLCorrente(e.url);
    }

    if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
      // On small screens collapse sidenav
      if (this.layoutService.isSmallScreen() && !this.layoutService.isCollapsed()) {
        setTimeout(() => this.layoutService.setCollapsed(true, true), 10);
      }

      // Remove loading state
      document.body.classList.remove('app-loading');
    }
  }

  private trocarMenu(url: string) {
    if (url.indexOf('painel') >= 0) {
      this.menuService.exibir(TipoMenu.ESCOLA);

    } else if (url.indexOf('/grade_curricular/') >= 0) {
      this.menuService.exibir(TipoMenu.GRADE_CURRICULAR);

    } else if (url.indexOf('turma/') >= 0) {
      this.menuService.exibir(TipoMenu.TURMA);
    }
  }
}
