import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// *******************************************************************************
// Layouts

import { Layout1Component } from './layout/layout-1/layout-1.component';
import { Layout2Component } from './layout/layout-2/layout-2.component';
import {LayoutBlankComponent} from "./layout/layout-blank/layout-blank.component";

import {AcessoPermitidoGuard} from "./acesso-permitido.guard";

// Routes

const routes: Routes = [

  {path: '', redirectTo: '/redirecionador', pathMatch: 'full'},

  { path: 'login', component: LayoutBlankComponent, loadChildren: './login/login.module#LoginModule' },

  { path: 'redirecionador', loadChildren: './redirecionador/redirecionador.module#RedirecionadorModule' },

  { path: 'painel', component: Layout1Component,     canActivate: [AcessoPermitidoGuard], loadChildren: './dashboard/dashboard.module#DashboardModule' },

  { path: 'ajuda', component: Layout1Component,     loadChildren: './ajuda/ajuda.module#AjudaModule' },

  { path: 'painel_chegada', canActivate: [AcessoPermitidoGuard], loadChildren: './painel-chegada/painel-chegada.module#PainelChegadaModule' },

  { path: 'academico', component: Layout1Component,  canActivate: [AcessoPermitidoGuard], loadChildren: './academico/academico.module#AcademicoModule' },
  { path: 'arquivos', component: Layout1Component,   canActivate: [AcessoPermitidoGuard], loadChildren: './arquivos/arquivos.module#ArquivosModule' },
  { path: 'comercial', component: Layout1Component,  canActivate: [AcessoPermitidoGuard], loadChildren: './comercial/comercial.module#ComercialModule' },
  { path: 'comunicacao', component: Layout1Component,  canActivate: [AcessoPermitidoGuard], loadChildren: './comunicacao/comunicacao.module#ComunicacaoModule' },
  { path: 'financeiro', component: Layout1Component,  canActivate: [AcessoPermitidoGuard], loadChildren: './financeiro/financeiro.module#FinanceiroModule' },
  { path: 'pessoas', component: Layout1Component,    canActivate: [AcessoPermitidoGuard], loadChildren: './pessoas/pessoas.module#PessoasModule' },
  { path: 'suporte', component: Layout1Component,    loadChildren: './suporte/suporte.module#SuporteModule' },
  { path: 'video_aula', component: Layout1Component, canActivate: [AcessoPermitidoGuard], loadChildren: './video-aula/video-aula.module#VideoAulaModule' },

  { path: 'pesquisa', component: Layout1Component, canActivate: [AcessoPermitidoGuard], loadChildren: './pesquisa/pesquisa.module#PesquisaModule' },

  { path: 'monitoramento', component: Layout1Component, canActivate: [AcessoPermitidoGuard], loadChildren: './monitoramento/monitoramento.module#MonitoramentoModule' },

  // { path: 'merenda', component: Layout1Component, loadChildren: './merenda/merenda.module#MerendaModule' },
  //
  // { path: 'relatorio_alunos', component: Layout1Component, loadChildren: './relatorio-alunos/relatorio-alunos.module#RelatorioAlunosModule' },

  { path: 'relatorio_irmaos', component: Layout1Component, loadChildren: './relatorio-irmaos/relatorio-irmaos.module#RelatorioIrmaosModule' }
];

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
