import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {ChatService} from "../service/chat.service";
import {PushNotificationService} from "./push-notification.service";

@Injectable()
export class SuporteService {

  static suporteOnline:Observable<any>;
  private observerSuporteOnline:Observer<Boolean>;

  static novaMensagem:Observable<any>;
  private observerNovaMensagem:Observer<Boolean>;

  private static isSuporteOnline: boolean = false;

  private audioNovaMsgSuporte;

  private static iniciado: boolean = false;


  constructor(private chatService: ChatService) {
  }

  public iniciar() {
    if (SuporteService.iniciado) return;

    // console.log('iniciando o serviço do suporte');

    SuporteService.iniciado=true;


    SuporteService.suporteOnline = new Observable(observer => {
      this.observerSuporteOnline = observer;

      //obtem o status do suporte na inicialização do serviço
      this.chatService.isSuporteOnline().subscribe(res => {
        this.observerSuporteOnline.next(res);

        SuporteService.isSuporteOnline = res;
      });
    }).share();

    //informa a chegada de novas mensagens
    SuporteService.novaMensagem = new Observable(observer => {
      this.observerNovaMensagem = observer;
    });


    //inicializa o audio que notifica novas mensagens
    this.audioNovaMsgSuporte = new Audio();
    this.audioNovaMsgSuporte.src = "assets/audios/nova-mensagem.mp3";
    this.audioNovaMsgSuporte.load();

    PushNotificationService.currentMessage.subscribe(payload => {

      if (payload && payload['data']) {

        //trata a notificação de status do suporte
        if (payload['data']['isSuporteOnline']) {
          let isSuporteOnline = payload['data']['isSuporteOnline'] == 'true';

          this.observerSuporteOnline.next(isSuporteOnline);

          SuporteService.isSuporteOnline = isSuporteOnline;

        } else {
          //trata a notificação de nova mensagem
          if (payload['data']['novaMensagem']) {
            this.observerNovaMensagem.next(true);

            //toca o audio para notificar que existe uma nova mensagem do suporte
            this.audioNovaMsgSuporte.play();
          }
        }

      }

    });
  }

  public static getIsSuporteOnline() {
    return SuporteService.isSuporteOnline;
  }


  private monitorarMensagensSuporte() {

    // console.log('checando nova msg');
    //obtem apenas a ultima mensagem do chat pois apenas esta é suficiente para checar se existe nova mensagem
//     this.chatService.getMensagens(this.buscaMsgSuporte, 0, 1).subscribe(mensagens => {
//       // console.log(mensagens);
//       if (mensagens.length > 0) {
//         //verifica se a mensagem não foi lida e se não foi enviada pelo proprio usuario logado
//         let msg = mensagens[mensagens.length-1];
// // console.log(msg);
//         this.buscaMsgSuporte.idInicial = msg.id;
//
//         if (msg.usuario.id == this.appService.getUsuarioLogado().id || msg.lida) {
//           if (this.observerNovaMensagem)
//             this.observerNovaMensagem.next(false);
//
//           return;
//         }
//
//         if (this.observerNovaMensagem)
//           this.observerNovaMensagem.next(true);
//
//         //toca o audio para notificar que existe uma nova mensagem do suporte
//         this.audioNovaMsgSuporte.play();
//       }
//     }, erro => {
//       // clearInterval(this.promiseMonitoramentoMsgsSuporte);
//     });
  }
}
