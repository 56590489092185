import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatadorMilesimal'
})
export class FormatadorMilesimalPipe implements PipeTransform {

  transform(value: number): string {
    if (!value && value != 0) return '';

    return String(value).replace('.', ',');
  }
}
