import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {Aluno} from "../model/aluno";

@Injectable()
export class AlunoTransferidoService {

  alunoTransferido: Observable<any>;

  private observerAlunoTransferido:Observer<Aluno>;


  constructor() {
    this.alunoTransferido = new Observable(observer => {
      this.observerAlunoTransferido = observer;
    }).share();
  }

  public notificarAlunoTransferido(aluno:Aluno) {
    let timer = setInterval(() => {
      if (this.observerAlunoTransferido) {
        this.observerAlunoTransferido.next(aluno);
        clearInterval(timer);
      }
    }, 100);

  }

}
