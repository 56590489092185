export enum Funcionalidade {
  CADASTRAR_UNIDADE='',
  BUSCAR_ALUNOS='',
  EXIBIR_DASHBOARD_PRINCIPAL = '',
  GERENCIAR_DOCUMENTOS='',
  GERENCIAR_ENTREVISTA='',
  GERENCIAR_FINANCEIRO = 'financeiro/*',
  GERENCIAR_COLABORADORES='',
  EXIBIR_PAINEL_CHEGADA = 'painel_chegada/*',
  GERENCIAR_COMUNICACAO = 'comunicacao/*',
  LISTAR_GRADES_CURRICULARES='',
  LISTAR_TURMAS='',
  GERENCIAR_ALUNOS_TURMA='',
  GERENCIAR_AVALIACOES='',
  GERENCIAR_PROFESSORES='',
  PREENCHER_FICHA_ACOMPANHAMENTO=''
}
