import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'editor-texto',
  templateUrl: './editor-texto.component.html',
  styleUrls: ['./editor-texto.component.scss',
    '../../../vendor/libs/quill/typography.scss',
    '../../../vendor/libs/quill/editor.scss']
})
export class EditorTextoComponent implements OnInit {

  @Input()
  public texto: string;

  @Output()
  textoAlterado: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  public onTextoAlterado() {
    this.textoAlterado.emit(this.texto);
  }
}
