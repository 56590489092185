import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {GradeCurricular} from "../model/gradecurricular";

@Injectable()
export class GradeCurricularGerenciadaService {

  gradeRemovida: Observable<any>;

  private observerGradeRemovida:Observer<GradeCurricular>;


  constructor() {
    this.gradeRemovida = new Observable(observer => {
      this.observerGradeRemovida = observer;
    }).share();
  }

  public notificarGradeRemovida(grade:GradeCurricular) {
    let timer = setInterval(() => {
      if (this.observerGradeRemovida) {
        this.observerGradeRemovida.next(grade);
        clearInterval(timer);
      }
    }, 100);

  }

}
