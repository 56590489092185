import { Injectable, Injector } from '@angular/core';
import {BaseService} from "./base.service";
import {Matricula} from "../model/matricula";
import {Observable} from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class MatriculaService extends BaseService {

  constructor(private injector: Injector) {
    super(injector);

    this.setURL('matriculas');
  }

  confirmar(matricula: Matricula): Observable<string> {
    return this.http.get(this.getURL() + '/confirmar/' + matricula.id).map(response => response as string);
  }

  cancelar(matricula: Matricula): Observable<string> {
    return this.http.get(this.getURL() + '/cancelar/' + matricula.id).map(response => response as string);
  }

  aprovar(matricula: Matricula): Observable<string> {
    return this.http.get(this.getURL() + '/aprovar/' + matricula.id).map(response => response as string);
  }

  reprovar(matricula: Matricula): Observable<string> {
    return this.http.get(this.getURL() + '/reprovar/' + matricula.id).map(response => response as string);
  }

  informarDesistencia(matricula: Matricula): Observable<string> {
    return this.http.get(this.getURL() + '/informar_desistencia/' + matricula.id).map(response => response as string);
  }

  public atualizarGrade(id, idGradeCurricular) : Observable<any> {
    return this.http.get(this.getURL() + '/atualizar_grade/'+id+'/'+idGradeCurricular).map(response => response as any);
  }

  public atualizarTurma(id, idTurma) : Observable<any> {
    return this.http.get(this.getURL() + '/atualizar_turma/'+id+'/'+idTurma).map(response => response as any);
  }

  public obterFicha(id) : Observable<any> {
    return this.http.get(this.getURL() + '/obter_ficha/'+id, {responseType: 'blob'}).map(response => response as any);
  }

  public obterFichasGrade(idGrade) : Observable<any> {
    return this.http.get(this.getURL() + '/obter_fichas/'+idGrade, {responseType: 'blob'}).map(response => response as any);
  }

  public transferir(idGrade: number, matriculas: Matricula[]) : Observable<any> {
    return this.http.post(this.getURL() + '/transferir/'+idGrade, matriculas, {responseType: 'text'}).map(response => response as any);
  }

  public getNumeroMatriculasAguardandoConfirmacao(idGrade: number) : Observable<any> {
    return this.http.get(this.getURL() + '/numero_matriculas_aguardando_confirmacao/'+idGrade).map(response => response as any);
  }

  public gerarRelacaoAlunosMatriculados(idUnidade, ano) : Observable<any> {
    return this.http.get(this.getURL() + '/gerar_relacao_alunos_matriculados/' + idUnidade + '/' + ano, {responseType: 'blob'}).map(response => response as any);
  }

  public setObservacaoFichaMatricula(matricula: Matricula) : Observable<any> {
    return this.http.post(this.getURL() + '/set_observacao_ficha_matricula', matricula, {responseType: 'text'}).map(response => response as any);
  }

  public gerarNumeroMatricula(idUnidade: number) : Observable<any> {
    return this.http.get(this.getURL() + '/gerar_numero_matricula/' + idUnidade).map(response => response as any);
  }

  public esconder(id: number) : Observable<any> {
    return this.http.get(this.getURL() + '/esconder/' + id).map(response => response as any);
  }
}
