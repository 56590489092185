import { Injectable, Injector } from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseService {

  constructor(private injector: Injector) {
    super(injector);

    this.setURL('chat');
  }

  public getUsuarios(idUnidade, pagina?, numItens?) : Observable<any> {
    return this.http.get(this.getURL() + '/usuarios/' + idUnidade + (pagina ? '?pagina='+pagina+'&numItens='+numItens : '')).map(response => response as any);
  }

  public notificarMensagemLida(idMsg) : Observable<any> {
    return this.http.get(this.getURL() + '/notificar_mensagem_lida/'+idMsg).map(response => response as any);
  }

  public getNumMensagensNaoLidasUsuario(idUsuario) : Observable<number> {
    return this.http.get(this.getURL() + '/num_mensagens_nao_lidas/'+idUsuario).map(response => response as number);
  }

  public getMensagens(buscaMensagem, pagina?, numItens?) : Observable<any> {
    if (pagina || pagina == 0)
      return this.http.post(this.getURL() + '/mensagens?pagina='+pagina+'&numItens='+numItens, buscaMensagem).map(response => response as any);
    else
      return this.http.post(this.getURL() + '/mensagens', buscaMensagem).map(response => response as any);
  }

  public getNumMensagensNaoLidas(buscaMensagem) : Observable<number> {
    return this.http.post(this.getURL() + '/num_mensagens_nao_lidas', buscaMensagem).map(response => response as number);
  }

  public enviar(mensagem, idUsuario) : Observable<number> {
    return this.http.post(this.getURL() + '/enviar/'+idUsuario, mensagem).map(response => response as number);
  }

  public isSuporteOnline() : Observable<boolean> {
    return this.http.get(this.getURL() + '/suporte_online').map(response => response as boolean);
  }
}
