import {Matricula} from "./matricula";
import {GradeCurricular} from "./gradecurricular";

export class Turma {
	id: number;
	codigo: string;
	numVagas: number;
  gradeCurricular: GradeCurricular;
  online: boolean = false;

  numAlunos: number;
  matriculas: Matricula[];
}
