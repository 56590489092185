import { Injectable, Injector } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import {Endereco} from "../model/endereco";
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class EnderecoService extends BaseService {

  constructor(private injector: Injector) {
    super(injector);

    this.setURL('enderecos');
  }

  public consultarCEP(cep) : Promise<Endereco> {
    if (!cep) {
      return;
    }

    let endereco = new Endereco();

    return this.http.get('https://api.postmon.com.br/v1/cep/'+cep).toPromise().then(response => {
      endereco.cep = cep;
      endereco.logradouro = response['logradouro'];
      endereco.bairro = response['bairro'];
      endereco.cidade = response['cidade'];
      endereco.estado.sigla = response['estado'];

      return endereco;
    }).catch(data => {
      if (data.status == 404) {
        return null;
      }
    });
  }
}
