import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {Avaliacao} from "../model/avaliacao";

@Injectable()
export class AvaliacaoGerenciadaService {

  avaliacaoCadastrada: Observable<any>;
  avaliacaoRemovida: Observable<any>;

  private observerAvaliacaoCadastrada:Observer<Avaliacao>;
  private observerAvaliacaoRemovida:Observer<Avaliacao>;


  constructor() {
    this.avaliacaoCadastrada = new Observable(observer => {
      this.observerAvaliacaoCadastrada = observer;
    }).share();

    this.avaliacaoRemovida = new Observable(observer => {
      this.observerAvaliacaoRemovida = observer;
    }).share();
  }

  public notificarAvaliacaoCadastrada(avaliacao:Avaliacao) {
    let timer = setInterval(() => {
      if (this.observerAvaliacaoCadastrada) {
        this.observerAvaliacaoCadastrada.next(avaliacao);
        clearInterval(timer);
      }
    }, 100);

  }

  public notificarAvaliacaoRemovida(avaliacao:Avaliacao) {
    let timer = setInterval(() => {
      if (this.observerAvaliacaoRemovida) {
        this.observerAvaliacaoRemovida.next(avaliacao);
        clearInterval(timer);
      }
    }, 100);

  }
}
