import { Pipe, PipeTransform } from '@angular/core';
import {TipoUsuario} from "../model/tipo-usuario";

@Pipe({
  name: 'formatadorTipoUsuario'
})
export class FormatadorTipoUsuarioPipe implements PipeTransform {

  transform(value: any): string {
    return TipoUsuario[value];
  }

}
