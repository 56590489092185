import { Component, OnInit, Input } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tela-confirmacao',
  templateUrl: './tela-confirmacao.component.html',
  styleUrls: ['./tela-confirmacao.component.css']
})
export class TelaConfirmacaoComponent implements OnInit {

  @Input()
  public texto: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public confirmar(valor) {
      this.activeModal.close(valor);
  }

}
