import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {Disciplina} from "../model/disciplina";

@Injectable()
export class DisciplinaGerenciadaService {

  disciplinaCadastrada: Observable<any>;
  disciplinaRemovida: Observable<any>;

  private observerDisciplinaCadastrada:Observer<Disciplina>;
  private observerDisciplinaRemovida:Observer<Disciplina>;


  constructor() {
    this.disciplinaCadastrada = new Observable(observer => {
      this.observerDisciplinaCadastrada = observer;
    }).share();

    this.disciplinaRemovida = new Observable(observer => {
      this.observerDisciplinaRemovida = observer;
    }).share();
  }

  public notificarDisciplinaCadastrada(disciplina:Disciplina) {
    let timer = setInterval(() => {
      if (this.observerDisciplinaCadastrada) {
        this.observerDisciplinaCadastrada.next(disciplina);
        clearInterval(timer);
      }
    }, 100);

  }

  public notificarDisciplinaRemovida(disciplina:Disciplina) {
    let timer = setInterval(() => {
      if (this.observerDisciplinaRemovida) {
        this.observerDisciplinaRemovida.next(disciplina);
        clearInterval(timer);
      }
    }, 100);

  }
}
