import { NgModule } from '@angular/core';
import {SharedModule} from "../shared/shared.module";
import { AgmCoreModule } from '@agm/core';
import {FormUnidadeComponent} from "./form-unidade.component";

@NgModule({
  imports: [
    SharedModule,
    AgmCoreModule
  ],
  declarations: [FormUnidadeComponent],
  entryComponents: [FormUnidadeComponent]
})
export class FormUnidadeModule { }
