import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatadorData'
})
export class FormatadorDataPipe implements PipeTransform {

  transform(strData: string): string {
    if (strData && strData.split('-').length > 1) {
      let tokens = strData.split('-');

      return tokens[2] + '/' + tokens[1] + '/' + tokens[0];
    }

    return strData;
  }
}
