import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AgmCoreModule } from '@agm/core';
import { BlockUIModule } from 'ng-block-ui';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';


// *******************************************************************************
// NgBootstrap

import { NgbModule, NgbTypeaheadModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

// *********************************************************
// **********************
// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';
import { RequestInterceptor } from "./request-interceptor";

import {NgbPtDateParserFormatter} from "./ngb-pt-date-parser-formatter";
import { AlertaPermissaoComponent } from './alerta-permissao/alerta-permissao.component';
import {DadosColaboradorLogadoModule} from "./dados-colaborador-logado/dados-colaborador-logado.module";

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    AlertaPermissaoComponent
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    HttpClientModule,
    NgbTypeaheadModule.forRoot(),
    ToastrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAFKq8VdFvWWSYQ-N1fB6R_hvG_rjYeonM'
    }),
    BlockUIModule.forRoot(),

    // App
    AppRoutingModule,
    LayoutModule,
    DadosColaboradorLogadoModule
  ],

  providers: [
    Title,
    AppService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    {provide: LOCALE_ID, useValue: 'pt-BR'},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: NgbDateParserFormatter, useClass: NgbPtDateParserFormatter}
  ],
  entryComponents: [
    AlertaPermissaoComponent
  ],

  bootstrap: [
    AppComponent
  ],
})
export class AppModule {}
