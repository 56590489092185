import { Injectable } from '@angular/core';

@Injectable()
export class FormatoMoedaService {

  constructor() { }

  formatar(valor): string {
    if (typeof valor == 'number') {
      valor = valor.toFixed(0);

      valor = Math.ceil(valor);
    }

    var strValor = new String(valor).replace(/\D/g, '');

    if (!strValor.match(/\d+/)) {
      return "";
    }

    var resultado;

    var valorPuro = +strValor;

    if (valorPuro < 10) {
      resultado = '0,0' + valorPuro;

    } else if (valorPuro < 100) {
      resultado = '0,' + valorPuro;

    } else {
      var parteInteiraNum = Math.floor(valorPuro/100);
      var parteInteira = parteInteiraNum.toString();

      if (parteInteiraNum > 999) {
        parteInteira = Math.floor(parteInteiraNum / 1000) + '.';

        //obtem cada digito da parte centesimal
        var divider = 1000;

        while (divider >= 10) {
          parteInteira += Math.floor((parteInteiraNum % divider) / (divider/10));
          divider /= 10;
        }
      }

      //verifica se a parte decimal não é menor que 10, pois neste caso é preciso inserir um 0 no fim
      resultado = parteInteira+','+((valorPuro%100) < 10 ? '0' + (valorPuro%100) : (valorPuro%100));
    }

    return 'R$ '+resultado;
  }

  asNumero(valor) : number {
    return Number(String(valor).valueOf().replace('R$', '').replace(/[,|\\.]/g, ''))/100;
  }

}
