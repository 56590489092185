import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separadorChat'
})
export class SeparadorChatPipe implements PipeTransform {

  private diaAtual: Date = new Date();
  private ultimoSeparadorExibido: string='';


  transform(value: any, flagModoExibicao: boolean): any {
    if (!value) return '';

    this.diaAtual.setHours(0, 0, 0, 0);

    let data: Date;

    //verifica se é string ou Date
    if (String(value).length > 25) {
      data = new Date(Date.parse(value));
      data.setHours(0,0,0,0);

    } else {
      let tks = value.split('T')[0].split('-');

      //obtem a data informada como Date
      data = new Date(+tks[0], +tks[1] - 1, +tks[2], 0, 0, 0, 0);
    }


    let nomeSeparador='';

    //verifica se a data atual é de hoje, ontem ou de alguma data anterior
    if (this.diaAtual.getTime() == data.getTime()) {
      nomeSeparador = 'Hoje';

    } else if (this.diaAtual.getTime()-86400000 == data.getTime()) {
      nomeSeparador = 'Ontem';

    } else {
      nomeSeparador = (data.getDate()<10 ? '0' : '') + data.getDate() + '/' + ((data.getMonth()+1)<10 ? '0' : '') + (data.getMonth()+1) + '/' + data.getFullYear();
    }


    if (this.ultimoSeparadorExibido != nomeSeparador) {
      //verifica se deve apenas informar que deve exibir
      if (!flagModoExibicao) return true;

      this.ultimoSeparadorExibido = nomeSeparador;

      return nomeSeparador;

    } else {
      if (!flagModoExibicao) return false;

      return '';
    }
  }

}
