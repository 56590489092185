import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Router } from '@angular/router';

import {MatriculaService} from "../../service/matricula.service";
import {Matricula} from "../../model/matricula";
import {AppService} from "../../app.service";
import {Aluno} from "../../model/aluno";
import {MatriculaSelecionadaService} from "../matricula-selecionada.service";

@Component({
  selector: 'buscar-aluno',
  templateUrl: './buscar-aluno.component.html',
  styleUrls: ['./buscar-aluno.component.scss']
})
export class BuscarAlunoComponent implements OnInit {

  public matriculas: Matricula[]=[];


  buscaNomeAluno = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(termo => {
        if (termo.length < 3) {
          return [];

        } else {
          return this.matriculas.filter(matricula => {
            return matricula.aluno.nome.toLowerCase().indexOf(termo.toLowerCase()) > -1 || (matricula.aluno.codigo && matricula.aluno.codigo.toLowerCase().indexOf(termo.toLowerCase()) > -1);
          }).slice(0, 10);
        }
      })
    );

  formatadorBuscaAluno = (matricula: Matricula) => {
    this.matriculaSelecionadaService.notificarMatriculaSelecionada(matricula);

    this.carregarDashboardAluno(matricula.aluno);

    return '';
  };


  constructor(private appService: AppService,
              private router: Router,
              private matriculaSelecionadaService: MatriculaSelecionadaService,
              private matriculaService: MatriculaService) { }

  ngOnInit() {
    this.appService.getUnidadeCorrente().then(unidade => {
      this.matriculaService.buscar({idUnidade: unidade.id, listaStatusIgnorados: ['PENDENTE'], atributosBuscados: ['status', 'aluno.id', 'aluno.nome', 'aluno.foto.id',
        'aluno.codigo', 'gradeCurricular.serie.nome', 'gradeCurricular.serie.nivelEducacao', 'gradeCurricular.turno', 'turma.codigo'], campoOrdenado: 'aluno.nome', ordenacao: 'ASCENDENTE'}).subscribe(lista => {
        this.matriculas = lista;
      });
    });
  }

  private carregarDashboardAluno(alunoSel: Aluno) {
    this.router.navigateByUrl('academico/aluno/'+alunoSel.id);
  }
}
