import { Injectable, Injector } from '@angular/core';
import {BaseService} from "./base.service";

@Injectable({
	providedIn: 'root'
})
export class TipoTaxaService extends BaseService {

	constructor(private injector: Injector) {
		super(injector);

		this.setURL('tipos_taxa');
	}
}