import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SelectModule } from 'ng-select';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule as Ng2ChartsModule } from 'ng2-charts/ng2-charts';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UiSwitchModule } from 'ngx-ui-switch';
import { BlockUIModule } from 'ng-block-ui';
import { LaddaModule } from 'angular2-ladda';
import { QuillModule } from 'ngx-quill';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';

import {TipoDocumentoService} from "../service/tipo-documento.service";
import {AlunoService} from "../service/aluno.service";
import {AvaliacaoService} from "../service/avaliacao.service";
import {CargoService} from "../service/cargo.service";
import {DocumentoService} from "../service/documento.service";
import {MatriculaService} from "../service/matricula.service";
import {NomeDisciplinaService} from "../service/nome-disciplina.service";
import {SerieService} from "../service/serie.service";
import {TipoMediaService} from "../service/tipo-media.service";
import {TipoTaxaService} from "../service/tipo-taxa.service";
import {FormatoMoedaDirective} from "./formato-moeda.directive";
import {FormatoMoedaService} from "./formato-moeda.service";
import {MascaraDirective} from "./mascara.directive";
import {UploadComponent} from "./upload/upload.component";
import {TurmaService} from "../service/turma.service";
import {DisciplinaService} from "../service/disciplina.service";
import {UnidadeService} from "../service/unidade.service";
import {FormatadorTurnoPipe} from "./formatador-turno.pipe";
import {FormatadorStatusMatriculaPipe} from "./formatador-status-matricula.pipe";
import {MenuService} from "./menu.service";
import {GradeCurricularSelecionadaService} from "./grade-curricular-selecionada.service";
import { EnderecoComponent } from './endereco/endereco.component';
import {SelectEstadoComponent} from "./select-estado/select-estado.component";
import {BuscarAlunoComponent} from "./buscar-aluno/buscar-aluno.component";
import {ArquivoService} from "../service/arquivo.service";
import { UrlFotoUsuarioPipe } from './url-foto-usuario.pipe';
import {TelaConfirmacaoComponent} from "./tela-confirmacao/tela-confirmacao.component";
import { DataSemHoraPipe } from './data-sem-hora.pipe';
import { FormatadorStatusMensalidadePipe } from './formatador-status-mensalidade.pipe';
import { NomesAlunosPipe } from './nomes-alunos.pipe';
import { FormatadorTipoUsuarioPipe } from './formatador-tipo-usuario.pipe';
import {AlunoSelecionadoService} from "./aluno-selecionado.service";
import {MatriculaSelecionadaService} from "./matricula-selecionada.service";
import {AlunoTransferidoService} from "./aluno-transferido.service";
import {TurmaGerenciadaService} from "./turma-gerenciada.service";
import {DisciplinaGerenciadaService} from "./disciplina-gerenciada.service";
import {AvaliacaoGerenciadaService} from "./avaliacao-gerenciada.service";
import {TemPermissaoDirective} from "./tem-permissao.directive";
import { FormatadorMilesimalPipe } from './formatador-milesimal.pipe';
import { FormatadorStatusBoletoPipe } from './formatador-status-boleto.pipe';
import {FormatadorNivelEducacaoPipe} from "./formatador-nivel-educacao.pipe";
import {SuporteService} from "./suporte.service";
import { ModalSelecaoUsuarioComponent } from './modal-selecao-usuario/modal-selecao-usuario.component';
import {NomeAvaliacaoService} from "../service/nome-avaliacao.service";
import { EditorTextoComponent } from './editor-texto/editor-texto.component';
import {TopicoService} from "../service/topico.service";
import {MensagemTopicoService} from "../service/mensagem-topico.service";
import {FormatadorTipoDivulgacaoPipe} from "./formatador-tipo-divulgacao.pipe";
import {SeparadorChatPipe} from "./separador-chat.pipe";
import {ProfessorGerenciadoService} from "./professor-gerenciado.service";
import {MatriculaGerenciadaService} from "./matricula-gerenciada.service";
import { FormatadorDataPipe } from './formatador-data.pipe';
import {GradeCurricularGerenciadaService} from "./grade-curricular-gerenciada.service";
import {PushNotificationService} from "./push-notification.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    NgbModule,
    NgbTypeaheadModule,
    NgxDatatableModule,
    SelectModule,
    UiSwitchModule,

    Ng2ChartsModule,
    PerfectScrollbarModule,
    BlockUIModule,
    LaddaModule,
    QuillModule,

    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyAFKq8VdFvWWSYQ-N1fB6R_hvG_rjYeonM',
      authDomain: 'czar360-1316.firebaseapp.com',
      projectId: 'czar360-1316',
      storageBucket: 'czar360-1316.appspot.com',
      databaseURL: 'https://czar360-1316.firebaseio.com/',
      messagingSenderId: '870330287246'
    })
  ],
  declarations: [
    FormatoMoedaDirective,
    MascaraDirective,
    UploadComponent,
    FormatadorTurnoPipe,
    FormatadorStatusMatriculaPipe,
    EnderecoComponent,
    SelectEstadoComponent,
    BuscarAlunoComponent,
    UrlFotoUsuarioPipe,
    TelaConfirmacaoComponent,
    DataSemHoraPipe,
    FormatadorStatusMensalidadePipe,
    NomesAlunosPipe,
    FormatadorTipoUsuarioPipe,
    TemPermissaoDirective,
    FormatadorMilesimalPipe,
    FormatadorStatusBoletoPipe,
    FormatadorNivelEducacaoPipe,
    ModalSelecaoUsuarioComponent,
    EditorTextoComponent,
    FormatadorTipoDivulgacaoPipe,
    SeparadorChatPipe,
    FormatadorDataPipe
  ],
  providers: [
    AlunoService,
    AvaliacaoService,
    CargoService,
    DisciplinaService,
    DocumentoService,
    MatriculaService,
    NomeDisciplinaService,
    NomeAvaliacaoService,
    SerieService,
    TipoDocumentoService,
    TipoMediaService,
    TipoTaxaService,
    TurmaService,
    UnidadeService,
    ArquivoService,
    TopicoService,
    MensagemTopicoService,

    FormatoMoedaService,
    MenuService,
    GradeCurricularSelecionadaService,
    AlunoSelecionadoService,
    MatriculaSelecionadaService,
    AlunoTransferidoService,
    TurmaGerenciadaService,
    DisciplinaGerenciadaService,
    AvaliacaoGerenciadaService,
    SuporteService,
    ProfessorGerenciadoService,
    MatriculaGerenciadaService,
    GradeCurricularGerenciadaService,
    PushNotificationService
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgxDatatableModule,
    SelectModule,
    NgbTypeaheadModule,
    Ng2ChartsModule,
    PerfectScrollbarModule,
    UiSwitchModule,
    BlockUIModule,
    LaddaModule,

    FormatoMoedaDirective,
    MascaraDirective,
    TemPermissaoDirective,

    UploadComponent,
    EnderecoComponent,
    SelectEstadoComponent,
    BuscarAlunoComponent,
    TelaConfirmacaoComponent,
    EditorTextoComponent,

    FormatadorTurnoPipe,
    FormatadorStatusMatriculaPipe,
    UrlFotoUsuarioPipe,
    DataSemHoraPipe,
    FormatadorStatusMensalidadePipe,
    NomesAlunosPipe,
    FormatadorTipoUsuarioPipe,
    FormatadorMilesimalPipe,
    FormatadorStatusBoletoPipe,
    FormatadorNivelEducacaoPipe,
    FormatadorTipoDivulgacaoPipe,
    SeparadorChatPipe,
    FormatadorDataPipe
  ],
  entryComponents: [
    TelaConfirmacaoComponent,
    ModalSelecaoUsuarioComponent
  ]
})
export class SharedModule { }
