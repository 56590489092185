import { Pipe, PipeTransform } from '@angular/core';
import {ContratanteProgramaPontos} from "../comunicacao/model/contratante-programa-pontos";

@Pipe({
  name: 'nomesAlunos'
})
export class NomesAlunosPipe implements PipeTransform {

  transform(contratante: ContratanteProgramaPontos): string {
    console.log(contratante);
    if (!contratante.alunos) return '';

    let nomes: string = '';
    for (let aluno of contratante.alunos) {
      nomes  += aluno.nome + ', ';
    }
    nomes = nomes.substring(0, nomes.length-2);
console.log(nomes);
    return nomes;
  }

}
