import { Pipe, PipeTransform } from '@angular/core';
import {Usuario} from "../model/usuario";
import {ConfigService} from "../service/config.service";

@Pipe({
  name: 'urlFotoUsuario'
})
export class UrlFotoUsuarioPipe implements PipeTransform {

  transform(usuario: Usuario): string {
    if (usuario.foto && usuario.foto.id) {
      return ConfigService.getUrlRaiz()+'arquivos/'+usuario.foto.id;
    }

    return 'assets/imgs/default-avatar.jpg';
  }

}
