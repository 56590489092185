import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {Matricula} from "../model/matricula";

@Injectable()
export class MatriculaSelecionadaService {

  matriculaSelecionada:Observable<any>;

  private observerMatriculaSelecionada:Observer<Matricula>;


  constructor() {
    this.matriculaSelecionada = new Observable(observer => {
      this.observerMatriculaSelecionada = observer;
    }).share();
  }

  public notificarMatriculaSelecionada(matricula: Matricula) {
    //let timer = setInterval(() => {
    //  if (this.observerMatriculaSelecionada) {
    //    console.log(matricula);
    //    this.observerMatriculaSelecionada.next(matricula);
    //    clearInterval(timer);
    //  }
    //}, 100);
  }
}
