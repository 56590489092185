import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {Turno} from "../../model/turno";
import {Serie} from "../../model/serie";
import {Turma} from "../../model/turma";
import {MatriculaService} from "../../service/matricula.service";
import {Matricula} from "../../model/matricula";
import {CategoriaAluno} from "../../comunicacao/model/categoria-aluno";
import {AppService} from "../../app.service";

@Component({
  selector: 'app-modal-selecao-usuario',
  templateUrl: './modal-selecao-usuario.component.html',
  styleUrls: ['./modal-selecao-usuario.component.scss',
    '../../../../node_modules/@swimlane/ngx-datatable/release/index.css',
    '../../../vendor/libs/ngx-datatable/ngx-datatable.scss',
    '../../../../node_modules/@swimlane/ngx-datatable/release/assets/icons.css'
  ]
})
export class ModalSelecaoUsuarioComponent implements OnInit {

  @ViewChild(DatatableComponent) table: DatatableComponent;

  @Input()
  private tipoUsuario: string;
  @Input()
  private turno: Turno;
  @Input()
  private serie: Serie;
  @Input()
  private turma: Turma;
  @Input()
  private categoriaAluno: CategoriaAluno;

  public matriculas: Matricula[]=[];
  public destinatarios=[];
  private temp = [];

  public selecionarTodos: boolean = true;

  public loadingIndicator: boolean = false;

  public nomeColuna: string;


  constructor(public activeModal: NgbActiveModal,
              private appService: AppService,
              private matriculaService: MatriculaService) { }

  ngOnInit() {
    this.loadingIndicator = true;

    //determina qual será o campo de ordenação
    let campoOrdenado;
    if (this.tipoUsuario == 'ALUNO') {
      campoOrdenado = 'aluno.nome';
      this.nomeColuna = 'Turma';

    } else if (this.tipoUsuario == 'RESPONSAVEL') {
      campoOrdenado = 'aluno.responsavel.nome';
      this.nomeColuna = 'Responsável por';
    }


    this.appService.getUnidadeCorrente().then(unidade => {
      this.matriculaService.buscar({idUnidade: unidade.id,
        anoGrade: new Date().getFullYear(),
        turno: this.turno,
        idSerie: this.serie ? this.serie.id : null,
        idTurma: this.turma ? this.turma.id : null,
        idCategoriaAluno: this.categoriaAluno && this.categoriaAluno.id != 'null' ? this.categoriaAluno.id : null, listaStatusIgnorados: ['PENDENTE', 'CANCELADA'],
        atributosBuscados: ['aluno.id', 'aluno.nome', 'aluno.responsavel.id', 'aluno.responsavel.nome', 'aluno.pai.id', 'aluno.pai.nome', 'aluno.mae.id',
          'aluno.mae.nome', 'gradeCurricular.serie.nome', 'gradeCurricular.serie.nivelEducacao', 'gradeCurricular.turno', 'turma.codigo'],
        campoOrdenado: campoOrdenado}).subscribe((lista: Matricula[]) => {

        let temp = [];

        for (let mat of lista) {
          let dest = new Destinatario();

          if (this.tipoUsuario == 'ALUNO') {
            dest.id = mat.aluno.id;
            dest.nome = mat.aluno.nome;
            dest.infoAdicional = mat.gradeCurricular.serie.nome + ' ' + mat.gradeCurricular.serie.nivelEducacao + ' ' + mat.gradeCurricular.turno + (mat.turma.codigo ? ' Turma ' + mat.turma.codigo : '');

          } else if (this.tipoUsuario == 'RESPONSAVEL') {
            let dadosAluno = mat.aluno.nome + ' - ' + mat.gradeCurricular.serie.nome + ' ' + mat.gradeCurricular.serie.nivelEducacao + ' ' + Turno[mat.gradeCurricular.turno] + (mat.turma.codigo ? ' Turma ' +
                mat.turma.codigo : '');

            dest.id = mat.aluno.responsavel.id;
            dest.nome = mat.aluno.responsavel.nome;
            dest.infoAdicional = dadosAluno;

            //verifica se existe pai informado e se não é o próprio responsável
            if (mat.aluno.pai.id != 0 && dest.id != mat.aluno.pai.id) {
              let dest1 = new Destinatario();
              dest1.id = mat.aluno.pai.id;
              dest1.nome = mat.aluno.pai.nome;
              dest1.infoAdicional = dadosAluno;

              temp.push(dest1);
            }

            //verifica se existe mãe informada e se não é o próprio responsável
            if (mat.aluno.mae.id != 0 && dest.id != mat.aluno.mae.id) {
              let dest2 = new Destinatario();
              dest2.id = mat.aluno.mae.id;
              dest2.nome = mat.aluno.mae.nome;
              dest2.infoAdicional = dadosAluno;

              temp.push(dest2);
            }
          }

          temp.push(dest);
        }

        this.destinatarios = temp;

        this.temp = [...this.destinatarios];

        this.loadingIndicator = false;
      });
    });

  }

  public filtrar(event) {
    const val = event.target.value.toLowerCase();

    // filtra os dados
    const temp = this.temp.filter(function(dest) {
      return (dest && dest.nome && dest.nome.toLowerCase().indexOf(val) !== -1) || !val;
    });

    // atualiza os registros
    this.destinatarios = temp;

    // exibe a página 1
    this.table.offset = 0;
  }

  public toogleTodos() {
    for (let r of this.destinatarios) {
      r.selecionado = this.selecionarTodos;
    }
  }

  public fechar() {
    this.activeModal.dismiss();
  }

  public confirmar() {
    let retorno = [];

    for (let r of this.destinatarios) {
      if (r.selecionado) {
        retorno.push(r);
      }
    }

    this.activeModal.close(retorno);
  }
}


class Destinatario {
  id: number;
  nome: string;
  infoAdicional: string;
  selecionado: boolean = true;
}
