import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {TipoMenu} from "./tipo-menu";

@Injectable()
export class MenuService {
  trocarMenu: Observable<any>;

  private observerTrocarMenu: Observer<TipoMenu>;


  constructor() {
    this.trocarMenu = new Observable(observer =>{
      this.observerTrocarMenu = observer;
    }).share();
  }

  public exibir(tipo: TipoMenu) {
    let promise = setInterval(() => {
      if (this.observerTrocarMenu) {
        this.observerTrocarMenu.next(tipo);
        clearInterval(promise);
      }
    }, 100);
  }
}
