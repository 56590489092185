import { Component, Input, Output, EventEmitter } from '@angular/core';
import {Arquivo} from "../../model/arquivo";

@Component({
  selector: 'upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {

  @Input('instancia')
  public arquivo: Arquivo;
  @Input('instancias')
  public arquivos: Arquivo[];
  @Output()
  arquivoPronto: EventEmitter<boolean> = new EventEmitter<boolean>();

  private controlFlag: number;


  constructor() { }

  private arquivoSelecionado($event) : void {
    this.controlFlag = $event.target.files.length;

    for (let file of $event.target.files) {

      if (this.arquivo) {
        this.loadFile(file, this.arquivo);
        this.arquivo.id = 0;

      } else {
        const arquivo = new Arquivo();

        this.arquivos.push(arquivo);

        this.loadFile(file, arquivo);
      }

    }
  }

  private loadFile(file, arquivo: Arquivo) {
    arquivo.id = 0;
    arquivo.nome = file.name;

    const myReader = new FileReader();

    myReader.onloadend = (e) => {
      const bytesArquivo = myReader.result.split(',')[1];

      arquivo.bytes = bytesArquivo;

      //verifica se todos os arquivos foram lidos
      if (--this.controlFlag == 0) {
        //informa que os arquivos estão prontos
        this.arquivoPronto.emit(true);
      }
    };
    myReader.readAsDataURL(file);
  }
}
