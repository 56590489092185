import { Injectable, Injector } from '@angular/core';
import {BaseService} from "./base.service";

@Injectable({
	providedIn: 'root'
})
export class EstadoService extends BaseService {

	constructor(private injector: Injector) {
		super(injector);

		this.setURL('estados');
	}
}