import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/share';
import {Observer} from 'rxjs/Observer';
import {Matricula} from "../model/matricula";

@Injectable()
export class MatriculaGerenciadaService {

  matriculaAdicionada: Observable<any>;

  private observerMatriculaAdicionada:Observer<any>;


  constructor() {
    this.matriculaAdicionada = new Observable(observer => {
      this.observerMatriculaAdicionada = observer;
    }).share();
  }

  public notificarMatriculaAdicionada() {
    let timer = setInterval(() => {
      if (this.observerMatriculaAdicionada) {
        this.observerMatriculaAdicionada.next(null);
        clearInterval(timer);
      }
    }, 100);

  }
}
