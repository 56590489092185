import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import {Unidade} from "./model/unidade";
import {GradeCurricular} from "./model/gradecurricular";
import {Colaborador} from "./model/colaborador";

@Injectable()
export class AppService {

  private usuarioLogado: Colaborador;
  private unidadeCorrente: Unidade;
  private gradeCorrente: GradeCurricular;

  private urlCorrente: string;

  private manterLogado: boolean = false;


  constructor(private titleService: Title) {
  }

  public setUsuarioLogado(usuario: Colaborador) {
    localStorage.setItem('usuarioLogado', JSON.stringify(usuario));

    this.usuarioLogado = usuario;
  }

  public getUsuarioLogado() : Colaborador {
    if (!this.usuarioLogado)
      this.usuarioLogado = JSON.parse(localStorage.getItem('usuarioLogado'));

    return this.usuarioLogado;
  }

  public setUnidadeCorrente(unidade: Unidade) {
    if (typeof unidade !== "undefined") {
      localStorage.setItem('unidade', JSON.stringify(unidade));

      this.unidadeCorrente = unidade;
    }
  }

  public getUnidadeCorrente() : Promise<Unidade> {
    return new Promise(resolve => {
      let control = 0;

      let timer = setInterval(() => {

        if (this.unidadeCorrente) {
          clearInterval(timer);

          resolve(this.unidadeCorrente);

          //se após 1 segundo não tiver obtido a unidade então obtem do storage caso exista
        } else if (control == 10) {
          clearInterval(timer);

          if (localStorage.getItem("unidade")) {
            this.unidadeCorrente = JSON.parse(localStorage.getItem("unidade"));

            resolve(this.unidadeCorrente);

          } else {
            resolve(null);
          }
        }

        control++;
      }, 100);
    })
  }

  public setGradeCorrente(grade: GradeCurricular) {
    this.gradeCorrente = grade;
  }

  public getGradeCorrente() : GradeCurricular {
    return this.gradeCorrente;
  }

  public getGradeCorrentePromise() : Promise<GradeCurricular> {
    return new Promise(resolve => {
      let control = 0;

      let timer = setInterval(() => {
        if (this.gradeCorrente) {
          clearInterval(timer);

          resolve(this.gradeCorrente);

          //se após 1 segundo não tiver obtido a grade então obtem o id do storage caso exista
        } else if (control == 10 && localStorage.getItem("idGrade")) {
          clearInterval(timer);

          this.gradeCorrente = new GradeCurricular();
          this.gradeCorrente.id = +localStorage.getItem("idGrade");

          resolve(this.gradeCorrente);
        }

        control++;
      }, 100);
    })
  }

  public setURLCorrente(url: string) {
    this.urlCorrente = url;
  }

  public getURLCorrente() : string {
    return this.urlCorrente;
  }

  public getManterLogado() {
    return localStorage.getItem('manterLogado') != null ? localStorage.getItem('manterLogado') == 'true' : false;
  }

  public logout() {
    this.unidadeCorrente = null;
    this.usuarioLogado = null;
    this.gradeCorrente = null;

    localStorage.removeItem('usuarioLogado');
    localStorage.removeItem('unidade');
    localStorage.removeItem('idGrade');
    localStorage.removeItem('tipoMenu');
    localStorage.removeItem('token');
  }

  // Set page title
  set pageTitle(value: string) {
    this.getUnidadeCorrente().then(unidade => {
      this.titleService.setTitle(value + ' - ' + unidade.nome);
    });
  }

  // Check for RTL layout
  get isRTL() {
    return document.documentElement.getAttribute('dir') === 'rtl' ||
           document.body.getAttribute('dir') === 'rtl';
  }

  // Check if IE10
  get isIE10() {
    return typeof document['documentMode'] === 'number' && document['documentMode'] === 10;
  }

  // Layout navbar color
  get layoutNavbarBg() {
    return 'navbar-theme';
  }

  // Layout sidenav color
  get layoutSidenavBg() {
    return 'sidenav-theme';
  }

  // Layout footer color
  get layoutFooterBg() {
    return 'footer-theme';
  }

  // Animate scrollTop
  scrollTop(to: number, duration: number, element = document.scrollingElement || document.documentElement) {
    if (element.scrollTop === to) { return; }
    const start = element.scrollTop;
    const change = to - start;
    const startDate = +new Date();

    // t = current time; b = start value; c = change in value; d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) { return c / 2 * t * t + b; }
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };

    const animateScroll = function() {
      const currentDate = +new Date();
      const currentTime = currentDate - startDate;
      element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };

    animateScroll();
  }
}
