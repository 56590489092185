import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AlertaPermissaoComponent} from "./alerta-permissao/alerta-permissao.component";
import {UnidadeService} from "./service/unidade.service";
import {AppService} from "./app.service";
import {Colaborador} from "./model/colaborador";
import {Funcionalidade} from "./model/funcionalidade";

@Injectable({
  providedIn: 'root'
})
export class AcessoPermitidoGuard implements CanActivate {

  private mapaPermissoes: string[]=[];
  private modulosPermitidos: string[] = [];


  constructor(private modalService: NgbModal,
              private appService: AppService,
              private unidadeService: UnidadeService) {
    this.mapaPermissoes['COMUNICACAO'] = ['/comunicacao/*'];
    this.mapaPermissoes['FINANCEIRO'] = ['/financeiro/*'];
    this.mapaPermissoes['MONITOR_PROXIMIDADE'] = ['/painel_chegada/*'];
    this.mapaPermissoes['COLABORADORES'] = ['/pessoas/colaboradores/*'];
    this.mapaPermissoes['VIDEOAULAS'] = ['/video_aula/*'];
    this.mapaPermissoes['GESTAO'] = ['^(?!/comunicacao/).*$', '^(?!/financeiro/).*$', '^(?!/painel_chegada/).*$', '^(?!/video_aula/).*$'];

    //obtem os modulos liberados para a unidade
    this.appService.getUnidadeCorrente().then(unidade => {
      if (unidade) {
        this.unidadeService.getModulosLiberados(unidade.id).subscribe(lista => {
          this.modulosPermitidos = lista;
        });
      }
    });

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise(resolve => {
      let promise = setInterval(() => {
        if (this.modulosPermitidos.length) {
          clearInterval(promise);

          //verifica se a unidade possui permissao para a funcionalidade acessada
          for (let modulo of this.modulosPermitidos) {
            let temPermissaoModulo = true;

            //percorre o mapa das permissões para verificar se a URL corrente se enquadra em todas as regras de algum dos módulos
            for (let regexp of this.mapaPermissoes[modulo]) {

              let value;
              if (state.url.match(regexp))
                value = true;
              else
                value = false;

              //todas as regras para um modulo precisam ser respeitadas, por isso se determina o resultado corrente com o anterior
              temPermissaoModulo = temPermissaoModulo && value;
            }

            //verifica se tem permissao no modulo
            if (temPermissaoModulo) {
              //verifica se o usuario logado tem permissao na URL requisitada
              let usuarioLogado: Colaborador = this.appService.getUsuarioLogado();

              let temPermissao = false;

              for (let func of usuarioLogado.funcionalidades) {
                let regexp = Funcionalidade[func];

                if (state.url.match(regexp)) {
                  temPermissao = true;
                  break;
                }
              }

              //se não tiver permissão exibe mensagem de alerta
              if (!temPermissao)
                this.modalService.open(AlertaPermissaoComponent, { windowClass: 'modal-sm animate' }).componentInstance.permissaoFuncionalidade=true;

              resolve(temPermissao);
              return;
            }
          }

          //informa que o plano da unidade não concede acesso a funcionalidade selecionada
          this.modalService.open(AlertaPermissaoComponent, { windowClass: 'modal-sm animate' }).componentInstance.permissaoPacote=true;

          resolve(false);
        }
      }, 500);
    });
  }
}
