import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import {Colaborador} from "../model/colaborador";
import {AppService} from "../app.service";
import {ColaboradorService} from "../service/colaborador.service";

@Component({
  selector: 'app-dados-colaborador-logado',
  templateUrl: './dados-colaborador-logado.component.html',
  styleUrls: ['./dados-colaborador-logado.component.scss',
    '../../vendor/libs/ngx-toastr/ngx-toastr.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DadosColaboradorLogadoComponent implements OnInit {

  public colaborador: Colaborador;
  public alteracaoSenhaHabilitada: boolean= false;

  public erroNome: boolean = false;
  public erroEmail: boolean = false;

  options = {
    tapToDismiss: false,
    closeButton: false,
    positionClass: 'toast-top-right'
  };

  constructor(private activeModal: NgbActiveModal,
              private toastrService: ToastrService,
              private appService: AppService,
              private colaboradorService: ColaboradorService) {
    this.colaborador = new Colaborador();
  }

  ngOnInit() {
    let usuario = this.appService.getUsuarioLogado();

    this.colaborador.id = usuario.id;
    this.colaborador.nome = usuario.nome;
    this.colaborador.email = usuario.email;
  }

  public fechar() {
    this.activeModal.dismiss();
  }

  public salvar() {
    this.erroNome = false;
    this.erroEmail = false;

    if (!this.colaborador.nome) {
      this.erroNome = true;
      return;
    }

    if (!this.colaborador.email) {
      this.erroEmail = true;
      return;
    }


    this.colaboradorService.salvarDadosAcessoSistema(this.colaborador).subscribe(res => {
      this.toastrService.success('Seus dados foram alterados com sucesso!', 'Confirmação', this.options);

      this.activeModal.close(this.colaborador);

    }, err => {
      this.toastrService.warning(err.error, 'Alerta', this.options);
    });
  }
}
