import { Component, OnInit, Input } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alerta-permissao',
  templateUrl: './alerta-permissao.component.html',
  styleUrls: ['./alerta-permissao.component.scss']
})
export class AlertaPermissaoComponent implements OnInit {

  @Input()
  public permissaoPacote: boolean = false;
  @Input()
  public permissaoFuncionalidade: boolean = false;


  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public fechar() {
    this.activeModal.dismiss();
  }

}
