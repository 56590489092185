import { Injectable, Injector } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {BaseService} from "../../service/base.service";

@Injectable({
	providedIn: 'root'
})
export class DivulgacaoService extends BaseService {

	constructor(private injector: Injector) {
		super(injector);

		this.setURL('divulgacoes');
	}

	public removerAteData(deleteCommand) : Observable<any> {
		return this.http.post(this.getURL() + '/remover_ate_data', deleteCommand).map(response => response as any);
	}

  public habilitarPublicacaoFacebook(publicar, idUnidade, token) : Observable<any> {
    return this.http.get(this.getURL() + '/habilitar_publicacao_facebook/'+idUnidade+'/'+publicar+'?token='+token).map(response => response as any);
  }

  public aprovar(id) : Observable<any> {
    return this.http.get(this.getURL() + '/aprovar/'+id).map(response => response as any);
  }

  public rejeitar(id) : Observable<any> {
    return this.http.get(this.getURL() + '/rejeitar/'+id).map(response => response as any);
  }
}
