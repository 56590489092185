import { Component, OnInit, Input } from '@angular/core';
import {Subscription} from 'rxjs/Subscription';

import {Endereco} from "../../model/endereco";
import {EnderecoService} from "../../service/endereco.service";
import {EstadoService} from "../../service/estado.service";
import {Estado} from "../../model/estado";

@Component({
  selector: 'endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.scss',
    '../../../vendor/libs/ng-select/ng-select.scss']
})
export class EnderecoComponent implements OnInit {

  @Input("instancia")
  public endereco: Endereco;
  @Input()
  public checarPreenchimento: boolean = false;


  private subscriptionMatriculaSelecionada: Subscription;


  constructor(private enderecoService: EnderecoService,
              private estadoService: EstadoService) {
  }

  ngOnInit() {
    if (!this.endereco.estado) {
      this.endereco.estado = new Estado();
    }
  }

  public carregarDados() {
    this.enderecoService.consultarCEP(this.endereco.cep).then(obj => {console.log(obj);
      if (!obj) {
        return;
      }

      this.endereco.cep = obj.cep;
      this.endereco.logradouro = obj.logradouro;
      this.endereco.numero = obj.numero;
      this.endereco.complemento = obj.complemento;
      this.endereco.bairro = obj.bairro;
      this.endereco.cidade = obj.cidade;

      //obtem o id do estado obtido
      this.estadoService.buscar({sigla: obj.estado.sigla}).subscribe(res => {
        this.endereco.estado = res[0];
      });
    });
  }


  ngOnDestroy() {
    if (this.subscriptionMatriculaSelecionada) {
      this.subscriptionMatriculaSelecionada.unsubscribe();
    }
  }
}
