import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import {Unidade} from "../model/unidade";
import {UnidadeService} from "../service/unidade.service";
import {EmailAdicional} from "../model/email-adicional";

declare var google: any;

@Component({
  selector: 'app-form-unidade',
  templateUrl: './form-unidade.component.html',
  styleUrls: ['./form-unidade.component.scss']
})
export class FormUnidadeComponent implements OnInit {

  @Input() id: number;

  public unidade: Unidade;

  public titulo: string = 'Cadastro de unidade';

  public erroNome: boolean = false;
  public erroCNPJ: boolean = false;

  private geocoder: any;

  options = {
    tapToDismiss: false,
    closeButton: false,
    positionClass: 'toast-top-right'
  };

  constructor(public activeModal: NgbActiveModal,
              private mapsAPILoader: MapsAPILoader,
              private toastrService: ToastrService,
              private unidadeService: UnidadeService) {
    this.unidade = new Unidade();
  }

  ngOnInit() {
    if (this.id) {
      this.unidadeService.get(this.id).subscribe(obj => {
        this.unidade = obj;

        if (this.unidade.posicao) {
          this.unidade.posicao.latitude = +this.unidade.posicao.latitude;
          this.unidade.posicao.longitude = +this.unidade.posicao.longitude;
        }

        this.titulo = 'Edição de unidade';
      });

    } else {
      this.unidade.posicao.latitude = -16.679566;
      this.unidade.posicao.longitude = -49.256656;
    }


    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();

      if (!this.id) {
        this.preencherEndereco();
      }
    });
  }

  public atualizarEndereco(ev) {
    this.unidade.posicao.latitude = ev.coords.lat;
    this.unidade.posicao.longitude = ev.coords.lng;

    this.preencherEndereco();
  }

  private preencherEndereco() {
    Observable.create(observer => {

      this.geocoder.geocode({'latLng': {lat: this.unidade.posicao.latitude, lng: this.unidade.posicao.longitude}}, function(results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            observer.next(results[0].formatted_address);
            observer.complete();
          }

        } else {
          observer.next("");
          observer.complete();
        }
      });

    }).subscribe(resultado => {
      this.unidade.endereco = resultado;
    });
  }

  adicionarEmail() {
    this.unidade.emailsAdicionais.push(new EmailAdicional());
  }

  removerEmail(index) {
    this.unidade.emailsAdicionais.splice(index, 1);
  }

  public salvar() {
    this.erroNome = false;
    this.erroCNPJ = false;

    //verifica se os campos obrigatórios foram preenchidos
    if (!this.unidade.nome) {
      this.erroNome=true;
      this.toastrService.error('O campo nome é obrigatório!', 'Erro', this.options);
      return;
    }

    if (!this.unidade.cnpj) {
      this.erroCNPJ = true;
      this.toastrService.error('O campo CNPJ é obrigatório!', 'Erro', this.options);
      return;
    }

    this.unidadeService.salvar(this.unidade).subscribe(id => {
      this.unidade.id = id;

      this.toastrService.success('Unidade salva com sucesso!', 'Confirmação', this.options);

      this.activeModal.close(this.unidade);
    }, erro => {
      this.toastrService.error(erro.error, 'Erro', this.options);
    });
  }

  public fechar() {
    this.activeModal.dismiss();
  }
}
