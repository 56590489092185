import { Injectable, Injector } from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';

@Injectable({
	providedIn: 'root'
})
export class ProfessorService extends BaseService {

	constructor(private injector: Injector) {
		super(injector);

		this.setURL('professores');
	}

	public esconderTurma(idUsuario: number, idTurma: number) : Observable<any> {
	  return this.http.post(this.getURL() + '/'+idUsuario+'/esconder_turma/'+idTurma, {}).map(response => response as any);
  }
}
