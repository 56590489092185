import {Arquivo} from "./arquivo";
import {Unidade} from "./unidade";
import {EstadoCivil} from "./estadocivil";
import {Endereco} from "./endereco";
import {Posicao} from "./posicao";

export class Usuario {
	id: any;
	nome: string;
	cpf: string;
  rg: string;
	email: string;
	celular: string;
	telefone: string;
	foto: Arquivo;
	token: string;
  unidade: Unidade;
  nacionalidade: string;
  profissao: string;
  localTrabalho: string;
  estadoCivil: EstadoCivil;
  endereco: Endereco;
  tipo: any;
  saldo: number;
  posicao: Posicao;
  habilitado: boolean;
  checarSMS: boolean;
  cobrarUsoApp: boolean;

  urlFoto: string;
  numNovasMensagens: number;

  constructor() {
    this.estadoCivil = new EstadoCivil();
  }
}
