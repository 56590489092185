import { Injectable, Injector } from '@angular/core';
import {BaseService} from "./base.service";
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {Aluno} from "../model/aluno";

@Injectable({
  providedIn: 'root'
})
export class AlunoService extends BaseService {

  constructor(private injector: Injector) {
    super(injector);

    this.setURL('alunos');
  }

  public atualizarCategorias(id, categorias) : Observable<any> {
    return this.http.post(this.getURL() + '/atualizar_categorias/'+id, categorias).map(response => response as any);
  }

  public validarCodigo(id: number, codigo: string) : Observable<any> {
    return this.http.post(this.getURL() + '/validar_codigo/'+id+'/'+codigo, {}, {responseType: 'text'}).map(response => response as any);
  }

  public obterFichaProfessor(idTurma: number) : Observable<any> {
    return this.http.get(this.getURL() + '/obter_ficha_professor/' + idTurma, {responseType: 'blob'}).map(response => response as any);
  }

  public obterHistorico(id: number, nivelEducacao: string, observacoes?: string) : Observable<any> {
    return this.http.get(this.getURL() + '/obter_historico/'+id+'?nivelEducacao='+(nivelEducacao ? nivelEducacao : '')+'&observacoes=' + (observacoes ? observacoes : ''), {responseType: 'blob'}).map(response => response as any);
  }

  public obterDeclaracaoTransferencia(id) : Observable<any> {
    return this.http.get(this.getURL() + '/obter_declaracao_transferencia/' + id, {responseType: 'blob'}).map(response => response as any);
  }

  public salvarInformacoesExtras(aluno: Aluno) : Observable<any> {
    return this.http.post(this.getURL() + '/salvar_informacoes_extras', aluno).map(response => response as any);
  }

  public gerarArquivoCCBEU(id: number) : Observable<any> {
    return this.http.get(this.getURL() + '/gerar_arquivo_ccbeu/' + id, {responseType: 'blob'}).map(response => response as any);
  }

  public obterObservacoesHistorico(id: number) : Observable<string> {
    return this.http.get(this.getURL() + '/obter_observacoes_historico/' + id).map(response => response as string);
  }

}
